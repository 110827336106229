import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { FC, ReactNode } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { Translate } from '~/intl'
import { Button } from './ui/button'
import { cn } from '~/utils/cls'
import { NotFound } from './not-found'

type Props = {
	error?: string
	details?: string
	className?: string
}
export const ErrorPanel: FC<Props> = ({ error, details, className }) => (
	<div className={cn('m-4 flex items-center space-x-4', className)}>
		<ExclamationTriangleIcon
			className="text-yellow-500"
			width={24}
			height={24}
		/>
		<div className="text-sm">
			<div>{error ?? <Translate>error</Translate>}</div>
			<div className="text-muted-foreground ">
				{details ?? <Translate>error.details</Translate>}
			</div>
		</div>
	</div>
)

//TODO: report error
//TODO: add error id from backend to error message
export const ErrorScreen: FC<FallbackProps> = () => (
	<div className="flex h-dvh w-dvw flex-col items-center justify-center">
		<ErrorPanel />
		<Button onClick={() => (window.location.href = '/')}>
			<Translate>error.reload</Translate>
		</Button>
	</div>
)

export const WithError: FC<{
	error?: Error | unknown
	children: ReactNode | (() => ReactNode)
	notFound?: boolean
	className?: string
}> = ({ error, children, notFound, className }) => {
	if (error) {
		return <ErrorPanel className={className} />
	} else if (notFound) {
		return <NotFound className={className} />
	} else {
		return typeof children === 'function' ? children() : children
	}
}
