import { Crisp } from 'crisp-sdk-web'
import { FC } from 'react'
import { Combobox } from '~/components/combobox'
import { TabsMenu } from '~/components/tabs-menu'
import { Translate, useTranslate } from '~/intl'

export const MainMenu: FC<{ className?: string }> = ({ className }) => {
	const translate = useTranslate()

	return (
		<div className={className}>
			<div className="flex flex-col items-start justify-center gap-3 md:flex-row md:items-center md:justify-start">
				<Combobox
					className="w-full md:w-auto"
					items={[
						{
							value: 'production',
							label: 'Production',
						},
					]}
					selected="production"
					emptyLabel={translate('environments.select.placeholder')}
					onChange={() => {}}
				>
					<div
						className="cursor-pointer p-2 pb-4 text-xs text-gray-500 hover:underline"
						onClick={() => Crisp.chat.open()}
					>
						<Translate>environments.not_ready</Translate>
					</div>
				</Combobox>
				<TabsMenu className="inline-block max-w-full overflow-x-auto whitespace-nowrap">
					<TabsMenu.Link href="/">
						<Translate>menu.overview</Translate>
					</TabsMenu.Link>
					<TabsMenu.Link href="/queues">
						<Translate>menu.queues</Translate>
					</TabsMenu.Link>
					<TabsMenu.Link href="/schedules">
						<Translate>menu.schedules</Translate>
					</TabsMenu.Link>
					<TabsMenu.Link href="/settings">
						<Translate>menu.settings</Translate>
					</TabsMenu.Link>
				</TabsMenu>
			</div>
		</div>
	)
}
