import {
	DesktopIcon,
	ExitIcon,
	GearIcon,
	QuestionMarkCircledIcon,
} from '@radix-ui/react-icons'
import { FC } from 'react'
import { useAuth } from '~/auth'
import { Translate } from '~/intl'
import { Avatar } from './avatar'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from './ui/dropdown-menu'

export const UserHeader: FC = () => {
	const auth = useAuth()
	const user = auth.user
	const notifications = 0

	return (
		<div className="flex items-center gap-2">
			<button className="unset-all">
				<div className="hover-zoom-child relative">
					<Avatar src={user?.photoURL} size={64} />
					{notifications > 0 && (
						<div
							className="bg-foreground border-background text-background zoom-child transitoion absolute bottom-1 left-1 flex h-5 w-5 items-center justify-center rounded-full border-2 text-center align-middle font-bold transition-all duration-300"
							style={{ fontSize: '9px' }}
						>
							{notifications > 9 ? '9+' : notifications}
						</div>
					)}
				</div>
			</button>
			<div>
				<div className="text-sm text-gray-500">
					<Translate>user.header.title</Translate>
				</div>
				<div className="text-lg font-bold">
					{user?.displayName || <Translate>user.name.unknown</Translate>}
				</div>
			</div>
			<div className="flex flex-grow items-center justify-end pr-4 ">
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<GearIcon
							className="cursor-pointer text-gray-500 transition-all duration-300 hover:rotate-90"
							width={22}
							height={22}
						/>
					</DropdownMenuTrigger>
					<DropdownMenuContent className="w-56">
						<DropdownMenuLabel>
							<Translate>dropdown.misc</Translate>
						</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<DropdownMenuGroup>
							<DropdownMenuItem
								onClick={() =>
									window.open('https://docs.lambdaqueue.com/', '_blank')
								}
								className="cursor-pointer"
							>
								<QuestionMarkCircledIcon className="mr-2" />
								<Translate>help</Translate>
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={() =>
									window.open('https://lambdaqueue.com/', '_blank')
								}
								className="cursor-pointer"
							>
								<DesktopIcon className="mr-2" />
								<Translate>dropdown.landing</Translate>
							</DropdownMenuItem>
						</DropdownMenuGroup>
						<DropdownMenuLabel>
							<Translate>dropdown.account</Translate>
						</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<DropdownMenuGroup>
							<DropdownMenuItem
								onClick={auth.logout}
								className="cursor-pointer"
							>
								<ExitIcon className="mr-2" />
								<Translate>logout</Translate>
							</DropdownMenuItem>
						</DropdownMenuGroup>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</div>
	)
}
