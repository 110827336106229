import {
	CalendarIcon,
	ExclamationTriangleIcon,
	LayersIcon,
	PieChartIcon,
} from '@radix-ui/react-icons'
import { FC, ReactNode } from 'react'
import { Link } from 'wouter'
import SummaryCard from '~/components/summary-card'
import { Skeleton } from '~/components/ui/skeleton'
import { Translate } from '~/intl'

type Props = {
	loading?: boolean
	queues: number
	pending: number
	schedules: number
	nextSchedule: Date | null | undefined
	errorsToday: number
	lastErrorAt: Date | null | undefined
}
export const Summary: FC<Props> = ({
	loading,
	queues,
	pending,
	schedules,
	nextSchedule,
	errorsToday,
	lastErrorAt,
}) => {
	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
			<Link to="~/queues">
				<SummaryCard
					title={<Translate>dashboard.summary.queues.title</Translate>}
					icon={<LayersIcon strokeWidth="1px" height={24} width={24} />}
				>
					<SummaryCardBody
						loading={loading}
						title={
							<Translate count={pending}>
								dashboard.summary.queues.item_count
							</Translate>
						}
						desc={
							<Translate count={queues}>
								dashboard.summary.queues.queue_count
							</Translate>
						}
					/>
				</SummaryCard>
			</Link>
			<Link to="~/schedules">
				<SummaryCard
					title={<Translate>dashboard.summary.schedules.title</Translate>}
					icon={<CalendarIcon strokeWidth="1px" height={24} width={24} />}
				>
					<SummaryCardBody
						loading={loading}
						title={
							<Translate count={schedules}>
								dashboard.summary.schedules.count
							</Translate>
						}
						desc={
							nextSchedule ? (
								<Translate nextRun={nextSchedule}>
									dashboard.summary.schedules.next
								</Translate>
							) : (
								<Translate>dashboard.summary.schedules.no_next</Translate>
							)
						}
					/>
				</SummaryCard>
			</Link>
			<SummaryCard
				title={<Translate>dashboard.summary.errors.title</Translate>}
				icon={
					<ExclamationTriangleIcon strokeWidth="1px" height={24} width={24} />
				}
			>
				<SummaryCardBody
					className={errorsToday ? 'text-red-500' : ''}
					loading={loading}
					title={
						<Translate count={errorsToday}>
							dashboard.summary.errors.count
						</Translate>
					}
					desc={
						lastErrorAt ? (
							<Translate lastAt={lastErrorAt}>
								dashboard.summary.errors.last
							</Translate>
						) : (
							<Translate>dashboard.summary.errors.no_last</Translate>
						)
					}
				/>
			</SummaryCard>
			<SummaryCard
				title={<Translate>dashboard.summary.credits.title</Translate>}
				icon={<PieChartIcon strokeWidth="1px" height={24} width={24} />}
			>
				<SummaryCardBody
					loading={loading}
					title={<Translate>dashboard.summary.credits.infinity</Translate>}
					desc={
						<Translate days={0}>
							dashboard.summary.credits.plan_unlimited
						</Translate>
					}
				/>
			</SummaryCard>
		</div>
	)
}

const SummaryCardBody: FC<{
	className?: string
	loading?: boolean
	title: ReactNode
	desc: ReactNode
}> = ({ className, loading, title, desc }) => {
	if (loading) {
		return (
			<div>
				<Skeleton className="mb-2 h-6 w-28" />
				<Skeleton className="h-4 w-36" />
			</div>
		)
	} else {
		return (
			<div className={className}>
				<div className="text-2xl font-bold">{title}</div>
				<p className="text-muted-foreground text-xs">{desc}</p>
			</div>
		)
	}
}
