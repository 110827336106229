/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\tquery QueuesSchedulesAndEnvironments {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t}\n\t\tschedules {\n\t\t\tid\n\t\t\tkey\n\t\t}\n\t}\n": types.QueuesSchedulesAndEnvironmentsDocument,
    "\n\tquery DashboardData {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t}\n\t\tstats {\n\t\t\tqueues\n\t\t\tpending\n\t\t\tschedules\n\t\t\tnextSchedule\n\t\t\tfailedToday\n\t\t\tlastFailedAt\n\t\t}\n\t\thistogram {\n\t\t\tdate\n\t\t\tcompleted\n\t\t\tfailed\n\t\t}\n\t}\n": types.DashboardDataDocument,
    "\n\tquery QueueData($id: ID!) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t}\n\t}\n": types.QueueDataDocument,
    "\n\tquery QueueItem($id: ID!) {\n\t\tqueueItem(id: $id) {\n\t\t\tid\n\t\t\tcreated\n\t\t\tstate\n\t\t\ttries\n\t\t\tdelay\n\t\t\tschedule {\n\t\t\t\tid\n\t\t\t\tkey\n\t\t\t}\n\t\t\tpayloadType\n\t\t\tpayload\n\t\t\t... on QueueItem {\n\t\t\t\tstarted\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\trunAfter\n\t\t\t\terror\n\t\t\t}\n\t\t\t... on QueueHistoryItem {\n\t\t\t\tscheduled\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n": types.QueueItemDocument,
    "\n\tmutation DeleteQueueItem($id: ID!) {\n\t\tdeleteQueueItem(id: $id)\n\t}\n": types.DeleteQueueItemDocument,
    "\n\tquery QueueHistory(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\thistory(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tcreated\n\t\t\t\t\tscheduled\n\t\t\t\t\tstate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.QueueHistoryDocument,
    "\n\tquery QueueItems(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\titems(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tcreated\n\t\t\t\t\tstarted\n\t\t\t\t\tstate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.QueueItemsDocument,
    "\n\tquery QueueDataStats($id: ID!) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t\trunning\n\t\t\tfailedToday\n\t\t\tprocessedToday\n\t\t\thistogram(days: 30) {\n\t\t\t\tdate\n\t\t\t\tcompleted\n\t\t\t\tfailed\n\t\t\t}\n\t\t}\n\t}\n": types.QueueDataStatsDocument,
    "\n\tmutation ConfigureQueue($queue: ID!, $config: QueueConfigInput!) {\n\t\tconfigureQueue(id: $queue, input: $config) {\n\t\t\tid\n\t\t\tpaused\n\t\t}\n\t}\n": types.ConfigureQueueDocument,
    "\n\tquery QueuesData {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t\trunning\n\t\t\thistogram(days: 7) {\n\t\t\t\tdate\n\t\t\t\tcompleted\n\t\t\t\tfailed\n\t\t\t}\n\t\t}\n\t}\n": types.QueuesDataDocument,
    "\n\tquery ScheduleData($id: ID!) {\n\t\tschedule(id: $id) {\n\t\t\tid\n\t\t\tkey\n\t\t\tpaused\n\t\t}\n\t}\n": types.ScheduleDataDocument,
    "\n\tmutation DeleteSchedule($id: ID!) {\n\t\tdeleteSchedule(id: $id)\n\t}\n": types.DeleteScheduleDocument,
    "\n\tquery ScheduleMainData($id: ID!) {\n\t\tschedule(id: $id) {\n\t\t\tid\n\t\t\tkey\n\t\t\tcreated\n\t\t\tupdated\n\t\t\tqueue\n\t\t\tnextRun\n\t\t\tlastRun\n\t\t\ttimezone\n\t\t\tpayload\n\t\t\tpayloadType\n\t\t\tpaused\n\t\t\tschedule {\n\t\t\t\t... on BasicSchedule {\n\t\t\t\t\tevery\n\t\t\t\t\tinterval\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t\t... on CronSchedule {\n\t\t\t\t\tcron\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t}\n\t\t\tsimulation(input: { days: 60, hours: 48, minutes: 30, seconds: 30 }) {\n\t\t\t\tgranularity\n\t\t\t\truns\n\t\t\t}\n\t\t}\n\t}\n": types.ScheduleMainDataDocument,
    "\n\tmutation ConfigureSchedule($id: ID!, $config: ScheduleConfigInput!) {\n\t\tconfigureSchedule(id: $id, input: $config) {\n\t\t\tid\n\t\t\tpaused\n\t\t}\n\t}\n": types.ConfigureScheduleDocument,
    "\n\tquery ScheduleRun($id: ID!) {\n\t\tqueueItem(id: $id) {\n\t\t\tid\n\t\t\tcreated\n\t\t\tqueue\n\t\t\tstate\n\t\t\tcreated\n\t\t\tpayloadType\n\t\t\tpayload\n\t\t\t... on QueueItem {\n\t\t\t\tstarted\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\trunAfter\n\t\t\t\terror\n\t\t\t}\n\t\t\t... on QueueHistoryItem {\n\t\t\t\tscheduled\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n": types.ScheduleRunDocument,
    "\n\tquery ScheduleRuns(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tschedule(id: $id) {\n\t\t\truns(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tstate\n\t\t\t\t\tcreated\n\t\t\t\t\tscheduled\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.ScheduleRunsDocument,
    "\n\tquery Schedules {\n\t\tschedules {\n\t\t\tid\n\t\t\tkey\n\t\t\tpaused\n\t\t\tschedule {\n\t\t\t\t... on BasicSchedule {\n\t\t\t\t\tevery\n\t\t\t\t\tinterval\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t\t... on CronSchedule {\n\t\t\t\t\tcron\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.SchedulesDocument,
    "\n\tquery SettingsData {\n\t\taccessTokens {\n\t\t\tid\n\t\t\tname\n\t\t\texpires\n\t\t}\n\t\tsignSecret\n\t}\n": types.SettingsDataDocument,
    "\n\tmutation DeleteToken($id: ID!) {\n\t\tdeleteAccessToken(id: $id)\n\t}\n": types.DeleteTokenDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery QueuesSchedulesAndEnvironments {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t}\n\t\tschedules {\n\t\t\tid\n\t\t\tkey\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery QueuesSchedulesAndEnvironments {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t}\n\t\tschedules {\n\t\t\tid\n\t\t\tkey\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery DashboardData {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t}\n\t\tstats {\n\t\t\tqueues\n\t\t\tpending\n\t\t\tschedules\n\t\t\tnextSchedule\n\t\t\tfailedToday\n\t\t\tlastFailedAt\n\t\t}\n\t\thistogram {\n\t\t\tdate\n\t\t\tcompleted\n\t\t\tfailed\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery DashboardData {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t}\n\t\tstats {\n\t\t\tqueues\n\t\t\tpending\n\t\t\tschedules\n\t\t\tnextSchedule\n\t\t\tfailedToday\n\t\t\tlastFailedAt\n\t\t}\n\t\thistogram {\n\t\t\tdate\n\t\t\tcompleted\n\t\t\tfailed\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery QueueData($id: ID!) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery QueueData($id: ID!) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery QueueItem($id: ID!) {\n\t\tqueueItem(id: $id) {\n\t\t\tid\n\t\t\tcreated\n\t\t\tstate\n\t\t\ttries\n\t\t\tdelay\n\t\t\tschedule {\n\t\t\t\tid\n\t\t\t\tkey\n\t\t\t}\n\t\t\tpayloadType\n\t\t\tpayload\n\t\t\t... on QueueItem {\n\t\t\t\tstarted\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\trunAfter\n\t\t\t\terror\n\t\t\t}\n\t\t\t... on QueueHistoryItem {\n\t\t\t\tscheduled\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery QueueItem($id: ID!) {\n\t\tqueueItem(id: $id) {\n\t\t\tid\n\t\t\tcreated\n\t\t\tstate\n\t\t\ttries\n\t\t\tdelay\n\t\t\tschedule {\n\t\t\t\tid\n\t\t\t\tkey\n\t\t\t}\n\t\t\tpayloadType\n\t\t\tpayload\n\t\t\t... on QueueItem {\n\t\t\t\tstarted\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\trunAfter\n\t\t\t\terror\n\t\t\t}\n\t\t\t... on QueueHistoryItem {\n\t\t\t\tscheduled\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation DeleteQueueItem($id: ID!) {\n\t\tdeleteQueueItem(id: $id)\n\t}\n"): (typeof documents)["\n\tmutation DeleteQueueItem($id: ID!) {\n\t\tdeleteQueueItem(id: $id)\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery QueueHistory(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\thistory(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tcreated\n\t\t\t\t\tscheduled\n\t\t\t\t\tstate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery QueueHistory(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\thistory(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tcreated\n\t\t\t\t\tscheduled\n\t\t\t\t\tstate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery QueueItems(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\titems(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tcreated\n\t\t\t\t\tstarted\n\t\t\t\t\tstate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery QueueItems(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\titems(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tcreated\n\t\t\t\t\tstarted\n\t\t\t\t\tstate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery QueueDataStats($id: ID!) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t\trunning\n\t\t\tfailedToday\n\t\t\tprocessedToday\n\t\t\thistogram(days: 30) {\n\t\t\t\tdate\n\t\t\t\tcompleted\n\t\t\t\tfailed\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery QueueDataStats($id: ID!) {\n\t\tqueue(id: $id) {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t\trunning\n\t\t\tfailedToday\n\t\t\tprocessedToday\n\t\t\thistogram(days: 30) {\n\t\t\t\tdate\n\t\t\t\tcompleted\n\t\t\t\tfailed\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation ConfigureQueue($queue: ID!, $config: QueueConfigInput!) {\n\t\tconfigureQueue(id: $queue, input: $config) {\n\t\t\tid\n\t\t\tpaused\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation ConfigureQueue($queue: ID!, $config: QueueConfigInput!) {\n\t\tconfigureQueue(id: $queue, input: $config) {\n\t\t\tid\n\t\t\tpaused\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery QueuesData {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t\trunning\n\t\t\thistogram(days: 7) {\n\t\t\t\tdate\n\t\t\t\tcompleted\n\t\t\t\tfailed\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery QueuesData {\n\t\tqueues {\n\t\t\tid\n\t\t\tdisplayName\n\t\t\tpaused\n\t\t\tsize\n\t\t\tfailed\n\t\t\tprocessed\n\t\t\trunning\n\t\t\thistogram(days: 7) {\n\t\t\t\tdate\n\t\t\t\tcompleted\n\t\t\t\tfailed\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery ScheduleData($id: ID!) {\n\t\tschedule(id: $id) {\n\t\t\tid\n\t\t\tkey\n\t\t\tpaused\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery ScheduleData($id: ID!) {\n\t\tschedule(id: $id) {\n\t\t\tid\n\t\t\tkey\n\t\t\tpaused\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation DeleteSchedule($id: ID!) {\n\t\tdeleteSchedule(id: $id)\n\t}\n"): (typeof documents)["\n\tmutation DeleteSchedule($id: ID!) {\n\t\tdeleteSchedule(id: $id)\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery ScheduleMainData($id: ID!) {\n\t\tschedule(id: $id) {\n\t\t\tid\n\t\t\tkey\n\t\t\tcreated\n\t\t\tupdated\n\t\t\tqueue\n\t\t\tnextRun\n\t\t\tlastRun\n\t\t\ttimezone\n\t\t\tpayload\n\t\t\tpayloadType\n\t\t\tpaused\n\t\t\tschedule {\n\t\t\t\t... on BasicSchedule {\n\t\t\t\t\tevery\n\t\t\t\t\tinterval\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t\t... on CronSchedule {\n\t\t\t\t\tcron\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t}\n\t\t\tsimulation(input: { days: 60, hours: 48, minutes: 30, seconds: 30 }) {\n\t\t\t\tgranularity\n\t\t\t\truns\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery ScheduleMainData($id: ID!) {\n\t\tschedule(id: $id) {\n\t\t\tid\n\t\t\tkey\n\t\t\tcreated\n\t\t\tupdated\n\t\t\tqueue\n\t\t\tnextRun\n\t\t\tlastRun\n\t\t\ttimezone\n\t\t\tpayload\n\t\t\tpayloadType\n\t\t\tpaused\n\t\t\tschedule {\n\t\t\t\t... on BasicSchedule {\n\t\t\t\t\tevery\n\t\t\t\t\tinterval\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t\t... on CronSchedule {\n\t\t\t\t\tcron\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t}\n\t\t\tsimulation(input: { days: 60, hours: 48, minutes: 30, seconds: 30 }) {\n\t\t\t\tgranularity\n\t\t\t\truns\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation ConfigureSchedule($id: ID!, $config: ScheduleConfigInput!) {\n\t\tconfigureSchedule(id: $id, input: $config) {\n\t\t\tid\n\t\t\tpaused\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation ConfigureSchedule($id: ID!, $config: ScheduleConfigInput!) {\n\t\tconfigureSchedule(id: $id, input: $config) {\n\t\t\tid\n\t\t\tpaused\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery ScheduleRun($id: ID!) {\n\t\tqueueItem(id: $id) {\n\t\t\tid\n\t\t\tcreated\n\t\t\tqueue\n\t\t\tstate\n\t\t\tcreated\n\t\t\tpayloadType\n\t\t\tpayload\n\t\t\t... on QueueItem {\n\t\t\t\tstarted\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\trunAfter\n\t\t\t\terror\n\t\t\t}\n\t\t\t... on QueueHistoryItem {\n\t\t\t\tscheduled\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery ScheduleRun($id: ID!) {\n\t\tqueueItem(id: $id) {\n\t\t\tid\n\t\t\tcreated\n\t\t\tqueue\n\t\t\tstate\n\t\t\tcreated\n\t\t\tpayloadType\n\t\t\tpayload\n\t\t\t... on QueueItem {\n\t\t\t\tstarted\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\trunAfter\n\t\t\t\terror\n\t\t\t}\n\t\t\t... on QueueHistoryItem {\n\t\t\t\tscheduled\n\t\t\t\tresultType\n\t\t\t\tresult\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery ScheduleRuns(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tschedule(id: $id) {\n\t\t\truns(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tstate\n\t\t\t\t\tcreated\n\t\t\t\t\tscheduled\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery ScheduleRuns(\n\t\t$id: ID!\n\t\t$limit: Int!\n\t\t$before: String\n\t\t$after: String\n\t\t$sort: SortOrder\n\t) {\n\t\tschedule(id: $id) {\n\t\t\truns(\n\t\t\t\tpage: { limit: $limit, before: $before, after: $after, sort: $sort }\n\t\t\t) {\n\t\t\t\tpage {\n\t\t\t\t\tpages\n\t\t\t\t\tpageNo\n\t\t\t\t\ttotal\n\t\t\t\t\tnextCursor\n\t\t\t\t\tprevCursor\n\t\t\t\t\tendCursor\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\tid\n\t\t\t\t\tkey\n\t\t\t\t\tstate\n\t\t\t\t\tcreated\n\t\t\t\t\tscheduled\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery Schedules {\n\t\tschedules {\n\t\t\tid\n\t\t\tkey\n\t\t\tpaused\n\t\t\tschedule {\n\t\t\t\t... on BasicSchedule {\n\t\t\t\t\tevery\n\t\t\t\t\tinterval\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t\t... on CronSchedule {\n\t\t\t\t\tcron\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery Schedules {\n\t\tschedules {\n\t\t\tid\n\t\t\tkey\n\t\t\tpaused\n\t\t\tschedule {\n\t\t\t\t... on BasicSchedule {\n\t\t\t\t\tevery\n\t\t\t\t\tinterval\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t\t... on CronSchedule {\n\t\t\t\t\tcron\n\t\t\t\t\tstartAt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery SettingsData {\n\t\taccessTokens {\n\t\t\tid\n\t\t\tname\n\t\t\texpires\n\t\t}\n\t\tsignSecret\n\t}\n"): (typeof documents)["\n\tquery SettingsData {\n\t\taccessTokens {\n\t\t\tid\n\t\t\tname\n\t\t\texpires\n\t\t}\n\t\tsignSecret\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation DeleteToken($id: ID!) {\n\t\tdeleteAccessToken(id: $id)\n\t}\n"): (typeof documents)["\n\tmutation DeleteToken($id: ID!) {\n\t\tdeleteAccessToken(id: $id)\n\t}\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;