import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { Translate } from '~/intl'
import { cn } from '~/utils/cls'

export const NotFound: FC<{ className?: string }> = ({ className }) => (
	<div className={cn('m-4 flex items-center space-x-4', className)}>
		<QuestionMarkCircledIcon className="text-gray-500" width={24} height={24} />
		<div className="text-sm">
			<div>
				<h4>
					<Translate>404</Translate>
				</h4>
			</div>
			<div className="text-muted-foreground ">
				<Translate>404.details</Translate>
			</div>
		</div>
	</div>
)
