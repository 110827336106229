import { isToday } from 'date-fns'
import { PropsWithoutRef, ReactNode } from 'react'
import { FormattedDate } from 'react-intl'
import { LargeVsSmall, breakpoints } from '~/style'
import { middleTruncate } from '~/utils/strings'

export const IdColumn = (item: { id: string }): ReactNode => (
	<span className="text-ellipsis whitespace-nowrap font-medium md:w-[12em]">
		{middleTruncate(8, item.id, 2)}
	</span>
)
export const KeyColumn = (item: {
	key?: string | number | null | undefined
}): ReactNode => <span>{item.key || '--'}</span>

export function DateColumn<T>(
	field: keyof T,
	placeholder?: ReactNode,
	props?: Omit<PropsWithoutRef<typeof FormattedDate>, 'value'>
): (item: Partial<Pick<T, typeof field>>) => ReactNode {
	return item => {
		const value = item[field]
		if (
			value instanceof Date ||
			typeof value === 'string' ||
			typeof value === 'number'
		) {
			const today = isToday(value)
			return (
				<LargeVsSmall
					breakpoint={breakpoints.lg}
					small={
						<FormattedDate
							value={value as Date}
							dateStyle={today ? undefined : 'short'}
							timeStyle={today ? 'medium' : undefined}
							{...props}
						/>
					}
					large={
						<FormattedDate
							value={value}
							dateStyle="short"
							timeStyle="medium"
							{...props}
						/>
					}
				/>
			)
		} else {
			return placeholder || null
		}
	}
}
