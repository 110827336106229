import { FC, useMemo } from 'react'
import formatXml from 'xml-formatter'
import { useTranslate } from '~/intl'

import logger from '~/utils/logger'
const log = logger('code-block')

const FORMATTERS = {
	'text/plain': (code: string) => code,
	'application/xml': (code: string) => {
		return formatXml(code, { indentation: '  ', collapseContent: true })
	},
	'application/json': (code: string) => {
		return JSON.stringify(JSON.parse(code), null, 2)
	},
} as const
export type SupportedFormatter = keyof typeof FORMATTERS

export const CodeBlock: FC<{
	mimeType: string
	code: Uint8Array | number[] | string
}> & {
	supportedFormatters: SupportedFormatter[]
	isSupported: (mimeType: string) => boolean
} = ({ mimeType, code }) => {
	const translate = useTranslate()

	const formatted = useMemo(() => {
		try {
			return formatCode(code, mimeType)
		} catch (e) {
			log.error('Failed to parse JSON', e)
			return translate('code.parse.error')
		}
	}, [code, mimeType, translate])

	return (
		<pre className="overflow-auto rounded border bg-gray-100 p-2">
			<code>{formatted}</code>
		</pre>
	)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
CodeBlock.supportedFormatters = Object.keys(FORMATTERS) as SupportedFormatter[]
CodeBlock.isSupported = (type: string | null | undefined): boolean => {
	type = type?.toLowerCase() || 'unknown'
	return type in FORMATTERS || type.startsWith('text')
}

const formatCode = (
	code: Uint8Array | number[] | string,
	type: string
): string => {
	let content: string
	if (code instanceof Uint8Array) {
		content = new TextDecoder().decode(code)
	} else if (Array.isArray(code)) {
		content = new TextDecoder().decode(new Uint8Array(code))
	} else {
		content = code
	}

	const formatter = FORMATTERS[type?.toLowerCase() as SupportedFormatter]
	content = formatter?.(content) || content?.toString()
	return content
		.split('\n')
		.map((line, idx) => `${idx + 1}: ${line}`)
		.join('\n')
}
