import { useQuery } from '@apollo/client'
import { FC } from 'react'
import { Route, useRoute } from 'wouter'
import { graphql } from '~/__gen__/gql'
import { MasterDetails } from '~/components/master-details'
import { SchedulesList } from './schedules-list'
import { ScheduleDetails } from './details'

const SchedulesQuery = graphql(/* GraphQL */ `
	query Schedules {
		schedules {
			id
			key
			paused
			schedule {
				... on BasicSchedule {
					every
					interval
					startAt
				}
				... on CronSchedule {
					cron
					startAt
				}
			}
		}
	}
`)
export const Schedules: FC = () => {
	const { data, loading, error } = useQuery(SchedulesQuery)

	const [hasDetailsHidden] = useRoute('/')

	return (
		<MasterDetails expanded={!hasDetailsHidden}>
			<SchedulesList error={error} loading={loading} items={data?.schedules} />
			<Route path="/:id" nest>
				{params => <ScheduleDetails id={params.id} />}
			</Route>
		</MasterDetails>
	)
}
