import { FC, ReactNode } from 'react'
import { QueueItem, QueueItemState } from '~/__gen__/gql/graphql'
import { Badge, BadgeProps } from '~/components/ui/badge'
import { Translate } from '~/intl'

export const StateCellRenderer = (
	item: Pick<QueueItem, 'state'>
): ReactNode => <StateBadge state={item.state} />

export const StateBadge: FC<{ state: QueueItem['state'] }> = ({ state }) => (
	<Badge variant={STATE_BADGE[state]}>
		<Translate>{'queue.item.state.' + state}</Translate>
	</Badge>
)

export const STATE_BADGE: Record<QueueItemState, BadgeProps['variant']> = {
	PENDING: 'outline',
	RUNNING: 'default',
	RETRY: 'destructive',
	FAILED: 'destructive',
	COMPLETED: 'secondary',
}
