import { FC, ReactNode } from 'react'
import { cn } from '~/utils/cls'
import { WithEmptyState } from './empty-state'
import { WithLoading } from './loading'
import { Card, CardContent, CardTitle } from './ui/card'
import { Skeleton } from './ui/skeleton'

type Props = {
	loading?: boolean
	error?: Error | null
	children?: ReactNode[]
	narrow?: boolean
	emptyState?: ReactNode
	className?: string
}

export const ListItem: FC<{
	title: ReactNode
	subtitle?: ReactNode
	tooltip?: string
	children?: ReactNode
}> = ({ title, subtitle, children }) => (
	<Card>
		<div className="flex items-center justify-between p-6">
			<div className="flex-1">
				<CardTitle>{title}</CardTitle>

				<p className="text-muted-foreground mt-1 align-middle text-sm leading-4">
					{subtitle}
				</p>
			</div>
			{children}
		</div>
	</Card>
)

export const List: FC<Props> & { Item: typeof ListItem } = ({
	loading,
	error,
	children,
	className,
	emptyState,
}) => {
	return (
		<WithLoading loading={loading} error={error} placeholder={ListPlaceholder}>
			{() => (
				<WithEmptyState
					emptyState={emptyState}
					hasData={loading || children?.length}
				>
					<div className={cn('flex flex-col gap-2', className)}>{children}</div>
				</WithEmptyState>
			)}
		</WithLoading>
	)
}
List.Item = ListItem

const ListPlaceholder = (): ReactNode => (
	<CardContent className="min-h-0 space-y-8 overflow-auto">
		{[1, 2, 3].map(i => (
			<div key={'placeholder-' + i} className="space-x-4">
				<Skeleton className="h-6 w-64" />
				<Skeleton className="h-4 w-48" />
			</div>
		))}
	</CardContent>
)
