import { useMutation, useQuery } from '@apollo/client'
import { ChevronLeftIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { Link, Route, Switch, useLocation } from 'wouter'
import { graphql } from '~/__gen__/gql'
import { useConfirm } from '~/components/confirm'
import { DotsMenu } from '~/components/dots-menu'
import { WithError } from '~/components/error'
import { SubMenu } from '~/components/sub-menu'
import { DropdownMenuItem } from '~/components/ui/dropdown-menu'
import { Translate } from '~/intl'
import { displayName } from '../models'
import { ScheduleDetailsMain } from './main'
import { ScheduleRuns } from './runs'

const ScheduleData = graphql(/* GraphQL */ `
	query ScheduleData($id: ID!) {
		schedule(id: $id) {
			id
			key
			paused
		}
	}
`)

const DeleteSchedule = graphql(/* GraphQL */ `
	mutation DeleteSchedule($id: ID!) {
		deleteSchedule(id: $id)
	}
`)

export const ScheduleDetails: FC<{ id: string; className?: string }> = ({
	id,
	className,
}) => {
	const [_, navigate] = useLocation()
	const confirm = useConfirm()
	const { data, error } = useQuery(ScheduleData, { variables: { id } })
	const [deleteSchedule] = useMutation(DeleteSchedule, {
		variables: { id },
		refetchQueries: ['Schedules'],
	})

	const handleDelete = async (): Promise<void> => {
		await deleteSchedule()
		navigate('~/schedules')
	}

	const schedule = data?.schedule

	return (
		<WithError error={error} notFound={data && !schedule} className={className}>
			{() => (
				<div className={className}>
					<div className="flex justify-between">
						<Link
							href="~/schedules"
							className="ml-[-0.5rem] flex items-center hover:underline"
						>
							<ChevronLeftIcon height={28} width={28} />
							<h3 className="flex-1">
								{schedule ? displayName(schedule) : schedule}
								{schedule?.paused ? (
									<span className="text-muted-foreground pl-1 font-bold ">
										[<Translate>paused</Translate>]
									</span>
								) : null}
							</h3>
						</Link>
						<DotsMenu>
							<DropdownMenuItem
								className="text-destructive"
								onClick={() => confirm.delete(handleDelete)}
							>
								<Translate>delete</Translate>
							</DropdownMenuItem>
						</DotsMenu>
					</div>
					<SubMenu className="mt-2">
						<SubMenu.Item href="/">
							<Translate>schedule.details.menu.main</Translate>
						</SubMenu.Item>
						<SubMenu.Item href="/runs" match="/runs(/.*)?">
							<Translate>schedule.details.menu.runs</Translate>
						</SubMenu.Item>
					</SubMenu>
					<div className="mt-6">
						<Switch>
							<Route path="/runs" nest>
								{() => <ScheduleRuns id={id} />}
							</Route>
							<Route>{() => <ScheduleDetailsMain id={id} />}</Route>
						</Switch>
					</div>
				</div>
			)}
		</WithError>
	)
}
