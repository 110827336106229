import { FC } from 'react'
import logoLarge from './img/logo-large.png'
type Props = {
	size?: number
	className?: string
}
export const Logo: FC<Props> = ({ size, className }) => (
	<img
		src={logoLarge}
		alt="Logo"
		className={className}
		style={{ width: size, height: size }}
	/>
)
