import { FC } from 'react'
import { Link } from 'wouter'
import { Schedule } from '~/__gen__/gql/graphql'
import { EmptyState } from '~/components/empty-state'
import IconPause from '~/components/img/icon-pause.svg'
import { List } from '~/components/list'
import { Card } from '~/components/ui/card'
import { Translate } from '~/intl'
import { CronLike } from './cron-like'
import { shortName } from './models'

type ItemProps = Pick<Schedule, 'id' | 'key' | 'paused' | 'schedule'>
type Props = {
	loading?: boolean
	error?: Error | null
	items?: ItemProps[]
	className?: string
}
export const SchedulesList: FC<Props> = ({
	items,
	loading,
	error,
	className,
}) => (
	<List
		loading={loading}
		error={error}
		className={className}
		emptyState={<ListEmptyState />}
	>
		{items?.map(item => <ScheduleItem key={item.id} item={item} />)}
	</List>
)

const ScheduleItem: FC<{ item: ItemProps }> = ({ item }) => {
	return (
		<Link href={`/${item.id}`} className="block">
			<List.Item
				title={
					<span>
						{shortName(item)}
						{item.paused ? (
							<IconPause
								className="text-muted-foreground ml-1 inline align-text-bottom"
								style={{ marginBottom: '1px' }}
								width={22}
								height={24}
							/>
						) : null}
					</span>
				}
				subtitle={<CronLike>{item.schedule}</CronLike>}
			></List.Item>
		</Link>
	)
}

const ListEmptyState = (): JSX.Element => (
	<Card>
		<EmptyState
			className="p-20"
			vertical
			title={<Translate>schedule.list.empty.title</Translate>}
			desc={
				<Translate
					link={text => (
						<Link className="font-bold hover:underline" href="/settings">
							{text}
						</Link>
					)}
				>
					schedule.list.empty.desc
				</Translate>
			}
		/>
	</Card>
)
