import { useMemo } from 'react'
import { useAuth } from '~/auth'
import config from '~/config'
import { checkOk, parseJson } from '~/utils/http'

export type CreatedApiToken = {
	id: string
	name?: string | null
	created: Date
	expires?: Date
	token: string
}
type SecurityWebApi = {
	//This has to be REST so we don't leak token anywhere outside of this UI
	createToken: (
		name: string | null | undefined,
		neverExpires: boolean
	) => Promise<CreatedApiToken>
}

export const useSecurityApi = (): SecurityWebApi => {
	const auth = useAuth()

	return useMemo(
		() => ({
			async createToken(
				name: string | null | undefined,
				neverExpires: boolean
			) {
				const token = await auth.getToken()
				return fetch(config.apiUrl + '/security/tokens', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + token,
					},
					body: JSON.stringify({
						name,
						neverExpires,
					}),
				})
					.then(checkOk)
					.then(parseJson)
					.then(
						(res: CreateTokenResponse): CreatedApiToken => ({
							...res,
							created: new Date(res.created),
							expires: res.expires ? new Date(res.expires) : undefined,
						})
					)
			},
		}),
		[auth]
	)
}

type CreateTokenResponse = {
	id: string
	name?: string | null
	created: string
	expires?: string
	token: string
}
