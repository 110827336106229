import { Schedule } from '~/__gen__/gql/graphql'
import { middleTruncate } from '~/utils/strings'

export const displayName = (schedule: Pick<Schedule, 'id' | 'key'>): string => {
	return schedule.key || middleTruncate(8, schedule.id, 2)
}

export const shortName = (schedule: Pick<Schedule, 'id' | 'key'>): string => {
	return middleTruncate(10, schedule.key) || middleTruncate(8, schedule.id, 2)
}
