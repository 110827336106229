import { FC } from 'react'
import { useQuery } from '@apollo/client'
import { graphql } from '~/__gen__/gql'
import { CellRenderers, DataTable } from '~/components/data-table/data-table'
import { GraphPagination } from '~/components/data-table/pagination'
import { WithError } from '~/components/error'
import { Translate } from '~/intl'
import { useSearchHelper } from '~/utils/router'
import { renderQueueItem } from './item-expanded'
import { StateCellRenderer } from './state-badge'

const ItemsData = graphql(/* GraphQL */ `
	query QueueItems(
		$id: ID!
		$limit: Int!
		$before: String
		$after: String
		$sort: SortOrder
	) {
		queue(id: $id) {
			id
			items(
				page: { limit: $limit, before: $before, after: $after, sort: $sort }
			) {
				page {
					pages
					pageNo
					total
					nextCursor
					prevCursor
					endCursor
				}
				items {
					id
					key
					created
					started
					state
				}
			}
		}
	}
`)

const PAGE_SIZE = 10
export const QueueItems: FC<{
	id: string
	className?: string
}> = ({ id, className }) => {
	const search = useSearchHelper()
	const before = search.get('before')
	const after = search.get('after')

	const { data, error } = useQuery(ItemsData, {
		variables: {
			id: decodeURIComponent(id),
			limit: PAGE_SIZE,
			sort: 'DESC',
			before,
			after,
		},
	})

	const queue = data?.queue
	const page = queue?.items?.page
	const items = queue?.items?.items || []

	return (
		<WithError error={error} notFound={data && !queue} className={className}>
			{() => (
				<div className={className}>
					<DataTable
						items={items}
						columns={columns}
						tableLayout="fixed"
						renderExpanded={renderQueueItem}
					/>
					<GraphPagination page={page} className="mt-4" />
				</div>
			)}
		</WithError>
	)
}

const columns = [
	{
		key: 'id',
		header: <Translate>queue.item.id</Translate>,
		render: CellRenderers.IdColumn,
		className: 'text-ellipsis overflow-hidden',
	},
	{
		key: 'key',
		header: <Translate>queue.item.key</Translate>,
		render: CellRenderers.KeyColumn,
		collapse: true,
	},
	{
		key: 'created',
		header: <Translate>queue.item.created</Translate>,
		render: CellRenderers.DateColumn('created'),
	},
	{
		key: 'started',
		header: <Translate>queue.item.started</Translate>,
		render: CellRenderers.DateColumn(
			'started',
			<Translate>queue.item.not_started</Translate>
		),
		collapse: true,
	},
	{
		key: 'state',
		header: <Translate>queue.item.state</Translate>,
		render: StateCellRenderer,
		align: 'center',
		className: 'w-32',
	},
] as const
