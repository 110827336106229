import { FC } from 'react'
import { FormattedDate } from 'react-intl'
import { Link } from 'wouter'
import { Queue } from '~/__gen__/gql/graphql'
import { EmptyState } from '~/components/empty-state'
import { List } from '~/components/list'
import { Card } from '~/components/ui/card'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '~/components/ui/tooltip'
import { Translate, useTranslate } from '~/intl'
import { cn } from '~/utils/cls'
import IconPause from '~/components/img/icon-pause.svg'
import { displayName } from './models'

type QueueProps = Pick<
	Queue,
	| 'id'
	| 'displayName'
	| 'processed'
	| 'failed'
	| 'paused'
	| 'running'
	| 'size'
	| 'histogram'
>
type Props = {
	loading?: boolean
	error?: Error | null
	items?: QueueProps[]
	narrow?: boolean
	className?: string
}
export const QueuesList: FC<Props> = ({
	items,
	loading,
	error,
	narrow,
	className,
}) => (
	<List
		loading={loading}
		error={error}
		className={className}
		emptyState={<ListEmptyState />}
	>
		{items?.map(item => <QueueItem key={item.id} {...item} narrow={narrow} />)}
	</List>
)

const QueueItem: FC<QueueProps & { narrow?: boolean }> = props => {
	const translate = useTranslate()
	return (
		<Link href={`/${encodeURIComponent(props.id)}`} className="block">
			<List.Item
				title={
					<span>
						{displayName(props)}
						{props.paused ? (
							<IconPause
								className="text-muted-foreground ml-1 inline align-text-bottom"
								style={{ marginBottom: '1px' }}
								width={22}
								height={24}
							/>
						) : null}
					</span>
				}
				subtitle={
					<span
						title={translate('queue.list.item.tooltip', {
							size: props.size,
							processed: props.processed,
							failed: props.failed,
							running: props.running,
						})}
					>
						{props.narrow ? (
							<>
								{props.size}/{props.processed}/{props.failed}/{props.running}
							</>
						) : (
							<Translate
								size={props.size}
								processed={props.processed}
								failed={props.failed}
								running={props.running}
							>
								queue.list.item.desc
							</Translate>
						)}
					</span>
				}
			>
				<ListItemHistogram
					className="ml-2 self-stretch"
					data={props.histogram}
					maxDays={props.narrow ? 2 : 7}
				/>
			</List.Item>
		</Link>
	)
}

const ListItemHistogram: FC<{
	data: QueueProps['histogram']
	maxDays?: number
	className?: string
}> = ({ data, className, maxDays }) => {
	const max = Math.max(...data.map(d => d.completed + d.failed))
	const len = data.length
	return (
		<TooltipProvider delayDuration={100}>
			<div
				className={cn(
					'flex items-end justify-end gap-1 overflow-hidden',
					className
				)}
			>
				{data.slice(data.length - (maxDays || 0)).map((item, i) => (
					<Tooltip key={i}>
						<TooltipTrigger asChild>
							<div
								className={cn(
									'relative h-full w-4 cursor-pointer opacity-50 transition-opacity duration-300 hover:opacity-100',
									{
										'hover:border':
											!item.completed && !item.failed && i !== len - 1,
									}
								)}
							>
								<div
									className="absolute bottom-0 left-0 right-0 bg-gray-400"
									style={{
										height: `${((item.completed + item.failed) / max) * 100}%`,
									}}
								>
									{i === len - 1 ? (
										<div
											className="absolute left-0 right-0 animate-pulse bg-gray-600"
											style={{ top: '-2px', height: '2px' }}
										/>
									) : null}
								</div>
							</div>
						</TooltipTrigger>
						<TooltipContent className="bg-gray-400 text-white">
							<FormattedDate value={item.date} dateStyle="short" />
							:&nbsp;
							<span className="font-bold">{item.completed}</span>
						</TooltipContent>
					</Tooltip>
				))}
			</div>
		</TooltipProvider>
	)
}

const ListEmptyState = (): JSX.Element => (
	<Card>
		<EmptyState
			className="p-20"
			vertical
			title={<Translate>queue.list.empty.title</Translate>}
			desc={
				<Translate
					link={text => (
						<Link className="font-bold hover:underline" href="/settings">
							{text}
						</Link>
					)}
				>
					queue.list.empty.desc
				</Translate>
			}
		/>
	</Card>
)
