import { CopyIcon } from '@radix-ui/react-icons'
import { FC, useState } from 'react'
import { TextSkeleton } from '~/components/loading'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '~/components/ui/tooltip'
import { Translate } from '~/intl'
import { truncate } from '~/utils/strings'

type Props = {
	loading?: boolean
	secret?: string
	className?: string
}

export const SignSecret: FC<Props> = ({ loading, secret, className }) => {
	const [showContent, setShowContent] = useState(false)
	const copySecret = (): void => {
		if (secret) {
			navigator.clipboard.writeText(secret)
		}
	}

	return (
		<Card className={className}>
			<TooltipProvider>
				<CardHeader className="flex flex-row justify-between">
					<div className="space-y-1">
						<CardTitle>
							<Translate>settings.sign_secret.title</Translate>
						</CardTitle>
						<CardDescription>
							<Translate>settings.sign_secret.header</Translate>
						</CardDescription>
					</div>
				</CardHeader>
				<CardContent>
					{loading ? (
						<TextSkeleton className="h-3 w-80" />
					) : (
						<div className="flex">
							{showContent ? secret : truncate(8, secret)}&nbsp;
							<Tooltip>
								<TooltipTrigger asChild>
									<button
										className="link text-primary inline-block p-0"
										onClick={copySecret}
									>
										<span className="sr-only">
											<Translate>copy</Translate>
										</span>
										<CopyIcon className="h-4 w-4" />
									</button>
								</TooltipTrigger>
								<TooltipContent>
									<Translate>copy</Translate>
								</TooltipContent>
							</Tooltip>
							&nbsp;
							<button
								className="link text-primary text-sm"
								onClick={() => setShowContent(!showContent)}
							>
								<Translate>
									{showContent
										? 'settings.sign_secret.hide'
										: 'settings.sign_secret.show'}
								</Translate>
							</button>
						</div>
					)}
				</CardContent>
			</TooltipProvider>
		</Card>
	)
}
