import { FC } from 'react'
import { useQuery } from '@apollo/client'
import { graphql } from '~/__gen__/gql'
import { DataTable, CellRenderers } from '~/components/data-table/data-table'
import { GraphPagination } from '~/components/data-table/pagination'
import { WithError } from '~/components/error'
import { Translate } from '~/intl'
import { useSearchHelper } from '~/utils/router'
import { StateCellRenderer } from './state-badge'
import { renderScheduleRun } from './run-expanded'

const ItemsData = graphql(/* GraphQL */ `
	query ScheduleRuns(
		$id: ID!
		$limit: Int!
		$before: String
		$after: String
		$sort: SortOrder
	) {
		schedule(id: $id) {
			runs(
				page: { limit: $limit, before: $before, after: $after, sort: $sort }
			) {
				page {
					pages
					pageNo
					total
					nextCursor
					prevCursor
					endCursor
				}
				items {
					id
					key
					state
					created
					scheduled
				}
			}
		}
	}
`)

const PAGE_SIZE = 10
export const ScheduleRuns: FC<{
	id: string
	className?: string
}> = ({ id, className }) => {
	const search = useSearchHelper()
	const before = search.get('before')
	const after = search.get('after')

	const { data, error } = useQuery(ItemsData, {
		variables: { id, limit: PAGE_SIZE, sort: 'DESC', before, after },
		fetchPolicy: 'cache-and-network',
	})

	const queue = data?.schedule
	const page = queue?.runs?.page
	const items = queue?.runs?.items || []

	return (
		<WithError error={error} notFound={data && !queue} className={className}>
			{() => (
				<div className={className}>
					<DataTable
						items={items}
						columns={columns}
						tableLayout="fixed"
						renderExpanded={renderScheduleRun}
					/>
					<GraphPagination page={page} className="mt-4" />
				</div>
			)}
		</WithError>
	)
}

const columns = [
	{
		key: 'id',
		header: <Translate>schedule.run.id</Translate>,
		render: CellRenderers.IdColumn,
		className: 'text-ellipsis overflow-hidden',
	},
	{
		key: 'scheduled',
		header: <Translate>schedule.run.started</Translate>,
		render: CellRenderers.DateColumn('scheduled'),
		collapse: true,
	},
	{
		key: 'created',
		header: <Translate>schedule.run.finished</Translate>,
		render: CellRenderers.DateColumn('created'),
		collapse: true,
	},
	{
		key: 'state',
		header: <Translate>schedule.run.state</Translate>,
		render: StateCellRenderer,
		align: 'center',
		className: 'w-32',
	},
] as const
