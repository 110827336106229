import { useMutation, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { FC, useCallback, useMemo } from 'react'
import {
	Bar,
	BarChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import { graphql } from '~/__gen__/gql'
import { HistogramItem, Queue } from '~/__gen__/gql/graphql'
import { useConfirm } from '~/components/confirm'
import { WithError } from '~/components/error'
import { WithLoading } from '~/components/loading'
import { PauseControl } from '~/components/pause-control'
import { Separator } from '~/components/ui/separator'
import { Skeleton } from '~/components/ui/skeleton'
import { Translate, useTranslate } from '~/intl'
import { colors } from '~/style'
import { cn } from '~/utils/cls'

const QueueData = graphql(/* GraphQL */ `
	query QueueDataStats($id: ID!) {
		queue(id: $id) {
			id
			displayName
			paused
			size
			failed
			processed
			running
			failedToday
			processedToday
			histogram(days: 30) {
				date
				completed
				failed
			}
		}
	}
`)

const ConfigureQueue = graphql(/* GraphQL */ `
	mutation ConfigureQueue($queue: ID!, $config: QueueConfigInput!) {
		configureQueue(id: $queue, input: $config) {
			id
			paused
		}
	}
`)

export const Stats: FC<{ id: string; className?: string }> = ({
	id,
	className,
}) => {
	const translate = useTranslate()
	const confirm = useConfirm()
	const { data, error, loading } = useQuery(QueueData, {
		variables: { id: decodeURIComponent(id) },
	})

	const [pauseQueue] = useMutation(ConfigureQueue)

	const handlePauseChange = useCallback(
		(pause: boolean) =>
			confirm.destructive(
				() =>
					pauseQueue({ variables: { queue: id, config: { paused: pause } } }),
				{
					title: translate(
						pause ? 'queue.pause.confirm' : 'queue.resume.confirm'
					),
					message: translate(
						pause ? 'queue.pause.confirm.desc' : 'queue.resume.confirm.desc'
					),
					ok: translate(pause ? 'pause' : 'resume'),
				}
			),
		[confirm, id, pauseQueue, translate]
	)

	const queue = data?.queue

	return (
		<WithError error={error} notFound={data && !queue} className={className}>
			{() => (
				<div className={cn('space-y-4', className)}>
					<Globals queue={queue} onPauseChange={handlePauseChange} />
					<Separator className="my-4" />
					<h4>
						<Translate days={30}>queue.details.chart.title</Translate>
					</h4>
					<Chart loading={loading} stats={queue?.histogram} />
				</div>
			)}
		</WithError>
	)
}

const Globals: FC<{
	onPauseChange: (pause: boolean) => void
	className?: string
	queue?: Pick<
		Queue,
		| 'paused'
		| 'size'
		| 'failed'
		| 'processed'
		| 'running'
		| 'processedToday'
		| 'failedToday'
	> | null
}> = ({ className, queue, onPauseChange }) => {
	return (
		<div className={cn('flex items-center gap-4', className)}>
			<div className="flex flex-col items-center justify-center border-r-2 px-6 pb-2 font-bold">
				<div
					style={{
						fontSize: '64px',
						verticalAlign: 'top',
						lineHeight: '1.2em',
					}}
				>
					{queue?.size || 0}
				</div>
				<div>
					<Translate>queue.details.queued</Translate>
				</div>
			</div>
			<div className="flex-1 text-sm">
				<div>
					<Translate>queue.details.running</Translate>
					{': '}
					{queue?.running || 0}
				</div>
				<div>
					<Translate>queue.details.completed</Translate>
					{': '}
					{queue?.processed || 0}
				</div>
				<div>
					<Translate>queue.details.failed</Translate>
					{': '}
					{queue?.failed || 0}
				</div>
				<div>
					<Translate>queue.details.today.completed</Translate>
					{': '}
					{queue?.processedToday || 0}
				</div>
				<div>
					<Translate>queue.details.today.failed</Translate>
					{': '}
					{queue?.failedToday || 0}
				</div>
			</div>
			<PauseControl paused={queue?.paused} onToggle={onPauseChange} />
		</div>
	)
}

const Chart: FC<{ loading?: boolean; stats?: HistogramItem[] }> = ({
	loading,
	stats,
}) => {
	const data = useMemo(() => {
		return stats?.map(stat => ({
			date: format(stat.date, 'dd MMM'),
			completed: stat.completed,
			failed: stat.failed,
		}))
	}, [stats])

	return (
		<WithLoading loading={loading} placeholder={PlaceholderChart}>
			{() => (
				<ResponsiveContainer width="100%" height={350}>
					<BarChart
						data={data}
						margin={{
							top: 20,
							right: 30,
							left: 0,
							bottom: 5,
						}}
					>
						<XAxis dataKey="date" fontSize={12} />
						<YAxis fontSize={12} />
						<Tooltip contentStyle={{ fontSize: '12px' }} />
						<Bar dataKey="completed" stackId="a" fill={colors.chartPrimary} />
						<Bar dataKey="failed" stackId="a" fill={colors.destructive} />
					</BarChart>
				</ResponsiveContainer>
			)}
		</WithLoading>
	)
}
const PlaceholderChart = (): JSX.Element => {
	return <Skeleton className="h-full w-full rounded-md" />
}
