import { FC } from 'react'
import { Schedule } from '~/__gen__/gql/graphql'
import { Translate } from '~/intl'

type Props = {
	children?: Schedule['schedule']
}
export const CronLike: FC<Props> = ({ children }) => {
	if (children?.__typename === 'CronSchedule') {
		return <span>{children.cron}</span>
	} else if (children?.__typename === 'BasicSchedule') {
		return (
			<Translate every={children.every}>
				{'schedule.basic.every.' + children.interval}
			</Translate>
		)
	}
}
