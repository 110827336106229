import { DotsVerticalIcon } from '@radix-ui/react-icons'
import { FC, PropsWithChildren } from 'react'
import { cn } from '~/utils/cls'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from './ui/dropdown-menu'

type Props = PropsWithChildren & {
	className?: string
}
export const DotsMenu: FC<Props> = ({ className, children }) => (
	<DropdownMenu>
		<DropdownMenuTrigger
			className={cn(
				'flex h-8  w-8 items-center justify-center text-gray-500 hover:text-black',
				className
			)}
		>
			<DotsVerticalIcon />
		</DropdownMenuTrigger>
		<DropdownMenuContent>{children}</DropdownMenuContent>
	</DropdownMenu>
)
