import { FC } from 'react'
import { Route, Switch } from 'wouter'
import { MainLayout } from '~/layout/main-layout'
import { Dashboard } from './dashboard'
import { Settings } from './settings'
import { Queues } from './queues'
import { Schedules } from './schedules'

export const Routes: FC = () => (
	<MainLayout>
		<Switch>
			<Route path="/queues" component={Queues} nest />
			<Route path="/schedules" component={Schedules} nest />
			<Route path="/settings" component={Settings} nest />
			<Route component={Dashboard} />
		</Switch>
	</MainLayout>
)
