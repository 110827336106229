import {
	AlertDialogCancel,
	AlertDialogOverlay,
	AlertDialogPortal,
	AlertDialogTitle,
} from '@radix-ui/react-alert-dialog'
import React, {
	FC,
	PropsWithChildren,
	ReactNode,
	createContext,
	useContext,
	useMemo,
} from 'react'
import { Translate, useTranslate } from '~/intl'
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
} from './ui/alert-dialog'

type Confirm = {
	delete: (
		onOk: () => unknown,
		settings?: { title?: string; message?: string; ok?: string }
	) => void
	destructive: (
		onOk: () => unknown,
		settings: { title: string; message?: string; ok?: string }
	) => void
}
const confirmContext = createContext<{
	dialog?: ReactNode
	setDialog: (dialog: ReactNode | null | undefined) => void
}>({ setDialog: () => {} })

export const useConfirm = (): Confirm => {
	const ctx = useContext(confirmContext)
	const translate = useTranslate()
	return {
		delete: (onOk, settings = {}) =>
			ctx.setDialog(
				<ConfirmDialog
					title={settings.title || translate('confirm.delete.title')}
					description={settings.message || translate('confirm.delete.desc')}
					ok={settings.ok || translate('confirm.delete.ok')}
					onCancel={() => ctx.setDialog(null)}
					onOk={async () => {
						try {
							await onOk()
						} finally {
							ctx.setDialog(null)
						}
					}}
					destructive={true}
				/>
			),
		destructive: (onOk, settings) =>
			ctx.setDialog(
				<ConfirmDialog
					title={settings.title}
					description={settings.message}
					ok={settings.ok || translate('ok')}
					onCancel={() => ctx.setDialog(null)}
					onOk={async () => {
						try {
							await onOk()
						} finally {
							ctx.setDialog(null)
						}
					}}
					destructive={true}
				/>
			),
	}
}

const ConfirmDialog: FC<{
	title: string
	description?: string
	cancel?: string
	ok?: string
	onCancel?: () => unknown
	onOk?: () => unknown
	destructive?: boolean
}> = props => {
	return (
		<AlertDialog
			open={true}
			onOpenChange={opened => {
				if (!opened && props.onCancel) {
					props.onCancel()
				}
			}}
		>
			<AlertDialogPortal>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle>{props.title}</AlertDialogTitle>
							{props.description && (
								<AlertDialogDescription>
									{props.description}
								</AlertDialogDescription>
							)}
						</AlertDialogHeader>
						<AlertDialogFooter className="mt-2 flex gap-2">
							{props.onCancel ? (
								<AlertDialogCancel>
									{props.cancel ?? <Translate>cancel</Translate>}
								</AlertDialogCancel>
							) : null}
							{props.onOk ? (
								<AlertDialogAction
									onClick={props.onOk}
									className={
										props.destructive
											? 'bg-destructive text-destructive-foreground hover:bg-destructive shadow-sm'
											: ''
									}
								>
									{props.ok ?? <Translate>ok</Translate>}
								</AlertDialogAction>
							) : null}
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialogPortal>
		</AlertDialog>
	)
}

export const ConfirmProvider: FC<PropsWithChildren> = ({ children }) => {
	const [dialog, setDialog] = React.useState<ReactNode | null>(null)
	const value = useMemo(() => ({ dialog, setDialog }), [dialog, setDialog])
	return (
		<confirmContext.Provider value={value}>
			{children}
			{dialog}
		</confirmContext.Provider>
	)
}
