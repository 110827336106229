import { initializeApp } from 'firebase/app'
import {
	AuthProvider,
	FacebookAuthProvider,
	GithubAuthProvider,
	GoogleAuthProvider,
	OAuthProvider,
	TwitterAuthProvider,
	UserInfo,
	getAuth,
	isSignInWithEmailLink,
	onAuthStateChanged,
	sendSignInLinkToEmail,
	signInWithEmailLink,
	signInWithPopup,
} from 'firebase/auth'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'wouter'
import config from '~/config'
import logger from '~/utils/logger'
import { AuthHook, LoginProvider, User } from './models'

const log = logger('firebase')

initializeApp(config.firebase)

const toUser = (fireUser: UserInfo | null): User | null => {
	return (
		fireUser && {
			uid: fireUser.uid,
			displayName: fireUser.displayName,
			email: fireUser.email,
			photoURL: fireUser.photoURL,
			providerId: fireUser.providerId,
		}
	)
}

const auth = getAuth()
export const globalAuth: AuthHook = {
	initialized: auth.currentUser !== null,
	user: toUser(auth.currentUser),
	getToken: async () => auth.currentUser?.getIdToken() || null,
	login: async (method, email) => {
		if (!auth) {
			throw new Error('Firebase context not initialized')
		}

		if (method === 'email') {
			if (!email) {
				throw new Error('Email address is required for email login')
			}

			window.localStorage.setItem('firebase-login-email', email)
			return await sendSignInLinkToEmail(auth, email, {
				url: window.location.origin,
				handleCodeInApp: true,
			}).catch(e => {
				log.error('Failed to login via email', e)
				window.localStorage.removeItem('firebase-login-email')
				throw e
			})
		} else {
			const providerFactory = LOGIN_PROVIDERS[method]
			return await signInWithPopup(auth, providerFactory()).catch(e => {
				log.error('Failed to login via ' + method, e)
				throw e
			})
		}
	},
	logout: async () => {
		await auth.signOut()
		window.location.href = '/login'
	},
	refresh: async () => {
		try {
			return auth.currentUser?.getIdToken() || null
		} catch (e) {
			await auth.signOut()
			throw e
		}
	},
}

export const useFirebaseAuth = (): AuthHook => {
	const [_location, navigate] = useLocation()
	const auth = useMemo(getAuth, [])

	const [hook, setHook] = useState<AuthHook>(globalAuth)

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			globalAuth.user = toUser(user)
			Promise.resolve(user?.getIdToken()).then(() => {
				globalAuth.initialized = true
				setHook({ ...globalAuth })
			})
		})
		return unsubscribe
	}, [auth])

	useEffect(() => {
		if (isSignInWithEmailLink(auth, window.location.href)) {
			//We need to store email that started auth in local storage
			//and retrieve it here, to make sure that correct email
			//is used for login
			//TODO: save returnTo in local storage and redirect to it after login
			const email = window.localStorage.getItem('firebase-login-email')
			signInWithEmailLink(auth, email || '', window.location.href)
				.then(async resp => {
					window.localStorage.removeItem('firebase-login-email')
					globalAuth.user = toUser(resp.user)
					globalAuth.initialized = true
					setHook({ ...globalAuth })
					navigate('/', { replace: true })
				})
				.catch(error => {
					log.error('Failed to login with email link', error)
					throw error
				})
		}
	}, [auth, navigate])

	return hook
}

const LOGIN_PROVIDERS: Record<LoginProvider, () => AuthProvider> = {
	twitter: () => new TwitterAuthProvider(),
	google: () => new GoogleAuthProvider(),
	apple: () => new OAuthProvider('apple.com'),
	fb: () => new FacebookAuthProvider(),
	github: () => new GithubAuthProvider(),
}
