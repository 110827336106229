import { useQuery } from '@apollo/client'
import { FC, ReactNode } from 'react'
import { Link } from 'wouter'
import { graphql } from '~/__gen__/gql'
import { QueueHistoryItem, QueueItem } from '~/__gen__/gql/graphql'
import { ErrorPanel } from '~/components/error'
import { WithLoading } from '~/components/loading'
import { PayloadView } from '~/components/payload-view'
import { Translate } from '~/intl'
import { FormattedTimestamp } from '~/intl/formatted'
import { BundleValues, useTranslate } from '~/intl/translate'
import { isHistory } from '~/queues'
import { StateBadge } from './state-badge'

export const renderScheduleRun = (item: Pick<QueueItem, 'id'>): ReactNode => (
	<RunDetailsLoader id={item.id} />
)

const FetchFullItem = graphql(/* GraphQL */ `
	query ScheduleRun($id: ID!) {
		queueItem(id: $id) {
			id
			created
			queue
			state
			created
			payloadType
			payload
			... on QueueItem {
				started
				resultType
				result
				runAfter
				error
			}
			... on QueueHistoryItem {
				scheduled
				resultType
				result
				error
			}
		}
	}
`)
export const RunDetailsLoader: FC<{ id: QueueItem['id'] }> = ({ id }) => {
	const { data, loading, error } = useQuery(FetchFullItem, {
		variables: { id },
	})
	const item = data?.queueItem

	return (
		<WithLoading loading={loading} error={error} notFound={data && !item}>
			{() => {
				if (!item) return null
				if (isHistory(item)) {
					return <RunDetails item={item} />
				} else {
					//Impossible in schedule runs
					return <ErrorPanel />
				}
			}}
		</WithLoading>
	)
}

const RunDetails: FC<{
	item: Pick<
		QueueHistoryItem,
		| 'id'
		| 'queue'
		| 'created'
		| 'scheduled'
		| 'created'
		| 'state'
		| 'payload'
		| 'payloadType'
		| 'result'
		| 'resultType'
		| 'error'
	>
}> = ({ item }) => {
	const translate = useTranslate()
	return (
		<div className="space-y-1">
			<DetailRow labelKey="schedule.run.id">{item.id}</DetailRow>
			<DetailRow labelKey="schedule.run.queue">
				<Link className="underline" href={`~/queues/${item.queue}`}>
					{item.queue}
				</Link>
			</DetailRow>
			<DetailRow labelKey="schedule.run.started">
				<FormattedTimestamp value={item.scheduled} />
			</DetailRow>
			<DetailRow labelKey="schedule.run.finished">
				<FormattedTimestamp value={item.created} />
			</DetailRow>
			<DetailRow labelKey="schedule.run.state">
				<StateBadge state={item.state} />
			</DetailRow>
			<DetailRow
				labelKey="schedule.run.payload"
				values={{
					type: item.payloadType ? item.payloadType : translate('none'),
				}}
			>
				<PayloadDetail mimeType={item.payloadType} payload={item.payload} />
			</DetailRow>
			<DetailRow
				labelKey="schedule.run.result"
				values={{
					type: item.resultType ? item.resultType : translate('none'),
				}}
			>
				<PayloadDetail mimeType={item.resultType} payload={item.result} />
			</DetailRow>
			{item.error ? (
				<DetailRow labelKey="schedule.run.error">{item.error}</DetailRow>
			) : null}
		</div>
	)
}

const PayloadDetail: FC<{
	mimeType?: string | null
	payload?: Uint8Array | number[] | string | null
}> = ({ mimeType, payload }) => (
	<div className="mt-1 overflow-x-auto">
		<PayloadView mimeType={mimeType} payload={payload} />
	</div>
)

const DetailRow: FC<{
	labelKey: string
	values?: BundleValues
	children: ReactNode
	className?: string
}> = ({ labelKey, values, children, className }) => (
	<div className={className}>
		<b>
			<Translate {...values}>{labelKey}</Translate>
		</b>
		: {children}
	</div>
)
