import { ChevronLeftIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { useQuery } from '@apollo/client'
import { Link, Route, Switch } from 'wouter'
import { graphql } from '~/__gen__/gql'
import { WithError } from '~/components/error'
import { SubMenu } from '~/components/sub-menu'
import { Translate } from '~/intl'
import { displayName } from '../models'
import { QueueItems } from './queue-items'
import { Stats } from './queue-stats'
import { QueueHistory } from './queue-history'

const QueueData = graphql(/* GraphQL */ `
	query QueueData($id: ID!) {
		queue(id: $id) {
			id
			displayName
			paused
		}
	}
`)

export const QueueDetails: FC<{ id: string; className?: string }> = ({
	id,
	className,
}) => {
	const { data, error } = useQuery(QueueData, {
		variables: { id: decodeURIComponent(id) },
	})

	const queue = data?.queue

	return (
		<WithError error={error} notFound={data && !queue} className={className}>
			{() => (
				<div className={className}>
					<div>
						<Link
							href="~/queues"
							className="ml-[-0.5rem] flex items-center hover:underline"
						>
							<ChevronLeftIcon height={28} width={28} />
							<h3 className="flex-1">
								{queue ? displayName(queue) : queue}
								{queue?.paused ? (
									<span className="text-muted-foreground pl-1 font-bold ">
										[<Translate>paused</Translate>]
									</span>
								) : null}
							</h3>
						</Link>
					</div>
					<SubMenu className="mt-2">
						<SubMenu.Item href="/">
							<Translate>queue.details.menu.stats</Translate>
						</SubMenu.Item>
						<SubMenu.Item href="/items" match="/items(/.*)?">
							<Translate>queue.details.menu.items</Translate>
						</SubMenu.Item>
						<SubMenu.Item href="/history" match="/history(/.*)?">
							<Translate>queue.details.menu.history</Translate>
						</SubMenu.Item>
					</SubMenu>
					<div className="mt-6">
						<Switch>
							<Route path="/items" nest>
								{() => <QueueItems id={id} />}
							</Route>
							<Route path="/history" nest>
								{() => <QueueHistory id={id} />}
							</Route>
							<Route>{() => <Stats id={id} />}</Route>
						</Switch>
					</div>
				</div>
			)}
		</WithError>
	)
}
