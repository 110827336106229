import { FC } from 'react'
import IconPause from '~/components/img/icon-pause.svg'
import { Translate } from '~/intl'
import { Button } from './ui/button'

type Props = {
	paused: boolean | null | undefined
	onToggle: (paused: boolean) => void
}
export const PauseControl: FC<Props> = ({ paused, onToggle }) => {
	return (
		<div className="flex flex-col items-center gap-4 self-stretch">
			<div className="flex flex-col items-center justify-center gap-1 text-gray-700">
				{paused ? (
					<PausedIndicator size={32} />
				) : (
					<RunningIndicator size={32} />
				)}
				<div className="text-xs">
					<Translate>{paused ? 'paused' : 'running'}</Translate>
				</div>
			</div>
			<Button
				className="min-w-20"
				variant="outline"
				size="sm"
				onClick={() => onToggle(!paused)}
			>
				<Translate>{paused ? 'resume' : 'pause'}</Translate>
			</Button>
		</div>
	)
}

const RunningIndicator: FC<{ size: number }> = ({ size }) => (
	<div
		className="animate-pulse rounded-full bg-gray-500"
		style={{ width: `${size}px`, height: `${size}px` }}
	></div>
)

const PausedIndicator: FC<{ size: number }> = ({ size }) => (
	<IconPause width={size} height={size} className="text-gray-500" />
)
