import { CopyIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import { FC, useId, useRef, useState } from 'react'
import { FormattedDate } from 'react-intl'
import { useConfirm } from '~/components/confirm'
import { EmptyState } from '~/components/empty-state'
import { CheckboxWithDescription } from '~/components/inputs'
import { TableLoadingRows, WithLoading } from '~/components/loading'
import { PopupDialog } from '~/components/popup-dialog'
import { Button } from '~/components/ui/button'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import { Input } from '~/components/ui/input'
import { Label } from '~/components/ui/label'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '~/components/ui/table'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '~/components/ui/tooltip'
import { Translate, useTranslate } from '~/intl'
import { middleTruncate } from '~/utils/strings'

type Props = {
	loading?: boolean
	tokens: TokenProps[]
	onDelete: (id: string) => void
	onCreate: (
		name: string | null | undefined,
		neverExpire: boolean
	) => Promise<TokenProps & { token: string }>
	className?: string
}
type TokenProps = {
	id: string
	name?: string | null
	expires?: Date
}

export const AccessTokens: FC<Props> = ({
	tokens,
	loading,
	onCreate,
	onDelete,
	className,
}) => {
	const [showCreateDialog, setShowCreateDialog] = useState(false)
	const translate = useTranslate()

	return (
		<Card className={className}>
			<CardHeader className="flex flex-row justify-between">
				<div className="space-y-1">
					<CardTitle>
						<Translate>settings.api_tokens.title</Translate>
					</CardTitle>
					<CardDescription>
						<Translate>settings.api_tokens.header</Translate>
					</CardDescription>
				</div>
				<Button onClick={() => setShowCreateDialog(true)} variant="secondary">
					<Translate>settings.api_tokens.create</Translate>
				</Button>
			</CardHeader>
			<CardContent>
				{loading || tokens?.length ? (
					<TokenTable loading={loading} tokens={tokens} onDelete={onDelete} />
				) : (
					<EmptyState
						className="p-14"
						title={translate('settings.api_tokens.empty.title')}
						desc={translate('settings.api_tokens.empty.desc')}
					/>
				)}
			</CardContent>
			{showCreateDialog && (
				<CreateNewTokenDialog
					onClose={() => setShowCreateDialog(false)}
					onCreate={onCreate}
				/>
			)}
		</Card>
	)
}

const TokenTable: FC<{
	loading?: boolean
	tokens: TokenProps[]
	onDelete: (id: TokenProps['id']) => unknown
}> = ({ loading, tokens, onDelete }) => {
	const confirm = useConfirm()
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableHead className="text-ellipsis whitespace-nowrap md:w-[12em]">
						<Translate>settings.api_tokens.id</Translate>
					</TableHead>
					<TableHead className="w-[10em]">
						<Translate>settings.api_tokens.expires</Translate>
					</TableHead>
					<TableHead>
						<Translate>settings.api_tokens.name</Translate>
					</TableHead>
					<TableHead className="text-right">
						<Translate>settings.api_tokens.actions</Translate>
					</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				{loading ? (
					<TableLoadingRows cols={4} rows={3} fillCols={3} />
				) : (
					tokens.map(token => (
						<TableRow key={token.id}>
							<TableCell className="font-medium" title={token.id}>
								{middleTruncate(12, token.id)}
							</TableCell>
							<TableCell>
								{token.expires ? (
									<FormattedDate value={token.expires} dateStyle="short" />
								) : (
									<Translate>settings.api_tokens.expire_never</Translate>
								)}
							</TableCell>
							<TableCell>{token.name}</TableCell>
							<TableCell className="text-right">
								<Button
									variant="link"
									className="p-0 text-gray-600"
									onClick={() => confirm.delete(() => onDelete(token.id))}
								>
									<Translate>delete</Translate>
								</Button>
							</TableCell>
						</TableRow>
					))
				)}
			</TableBody>
		</Table>
	)
}

const CreateNewTokenDialog: FC<{
	onCreate: (name: string, neverExipres: boolean) => Promise<{ token: string }>
	onClose: () => unknown
	token?: string | null
	error?: Error | null
	loading?: boolean
}> = ({ onCreate, onClose }) => {
	const translate = useTranslate()
	const inputId = useId()
	const input = useRef<HTMLInputElement>(null)
	const [neverExpires, setNeverExpires] = useState(false)

	const [token, setToken] = useState<{ token: string } | null>(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)
	const [allowClose, setAllowClose] = useState(true)
	const [closeButton, setCloseButton] = useState('')

	const handleCreate = (): void => {
		setLoading(true)
		setAllowClose(false)
		onCreate(input.current?.value || '', neverExpires)
			.then(setToken)
			.then(() => {
				let count = 5
				setCloseButton(`(${count})`)
				const interval = setInterval(() => {
					count--
					if (count <= 0) {
						setAllowClose(true)
						setCloseButton('')
						clearInterval(interval)
					} else {
						setCloseButton(`(${count})`)
					}
				}, 1000)
			})
			.catch(e => {
				setError(e)
				setAllowClose(true)
			})
			.finally(() => setLoading(false))
	}
	const copyToken = (): void => {
		if (token) {
			navigator.clipboard.writeText(token.token)
		}
	}

	return (
		<PopupDialog
			className="md:min-w-[510px]"
			onCancel={onClose}
			cancel={
				closeButton ? (
					closeButton
				) : token || error ? (
					<Translate>close</Translate>
				) : (
					<Translate>cancel</Translate>
				)
			}
			title={<Translate>settings.api_tokens.create.title</Translate>}
			description={<Translate>settings.api_tokens.create.desc</Translate>}
			ok={<Translate>settings.api_tokens.create.generate</Translate>}
			onOk={token || error ? undefined : handleCreate}
			disabled={loading || !allowClose}
			modal
		>
			<WithLoading loading={false} error={error} className="m-auto">
				{() =>
					token ? (
						<div>
							<Label htmlFor={inputId}>
								<Translate>settings.api_tokens.create.generated</Translate>
							</Label>
							<div className="flex items-center gap-2">
								<Input
									id={inputId}
									value={token.token}
									readOnly
									className="text-gray-700"
									autoFocus
								/>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												type="button"
												size="sm"
												className="px-3"
												onClick={copyToken}
											>
												<span className="sr-only">
													<Translate>copy</Translate>
												</span>
												<CopyIcon className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent>
											<Translate>copy</Translate>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</div>
							<div className="mt-4 flex items-center gap-2 text-sm">
								<InfoCircledIcon
									className="text-yellow-400"
									width={32}
									height={32}
								/>
								<Translate>
									settings.api_tokens.create.generated_notice
								</Translate>
							</div>
						</div>
					) : (
						<div className="grid grid-cols-1 gap-5">
							<div>
								<Label htmlFor={inputId}>
									<Translate>settings.api_tokens.create.name</Translate>
								</Label>
								<Input
									id={inputId}
									ref={input}
									autoFocus
									placeholder={translate(
										'settings.api_tokens.create.name_placeholder'
									)}
								/>
							</div>
							<CheckboxWithDescription
								label={
									<Translate>settings.api_tokens.create.expire_never</Translate>
								}
								description={
									<Translate>
										settings.api_tokens.create.expire_default
									</Translate>
								}
								checked={neverExpires}
								onChange={setNeverExpires}
							/>
						</div>
					)
				}
			</WithLoading>
		</PopupDialog>
	)
}
