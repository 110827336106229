{
	"404": "Not Found!",
	"404.details": "We could not find the thing you were looking for 🤷‍♂️",
	"ok": "Ok",
	"cancel": "Cancel",
	"copy": "Copy",
	"copied": "Copied",
	"close": "Close",
	"delete": "Delete",
	"footer.copyright": "© 2024 DayOne Labs",
	"footer.terms": "Terms",
	"footer.privacy": "Privacy",
	"footer.status": "System Status",
	"footer.contact": "Contact",
	"actions": "Actions",
	"help": "Documentation",
	"loading": "Loading...",
	"none": "None",
	"queued": "Queued",
	"running": "Running",
	"paused": "Paused",
	"pause": "Pause",
	"resume": "Resume",
	"error": "Oops, something went wrong...",
	"error.details": "Please reload the page and try again",
	"error.reload": "Reload Page",
	"code.parse.error": "Could not parse content",
	"code.binary": "-- Binary Data --",
	"code.empty": "-- Empty --",
	"empty.title": "Nothing to see here",
	"empty.desc": "There are no items to display",
	"menu.overview": "Overview",
	"menu.queues": "Queues",
	"menu.schedules": "Schedules",
	"menu.alerts": "Alerts",
	"menu.scriptlets": "Scriptlets",
	"menu.settings": "Settings",
	"table.pagination.showing": "Page {page} of {total}",
	"table.pagination.total": "Total: {count, plural, =0 {no items} one {1 item} other {{count} items}}",
	"table.pagination.goto_end": "Go to last page",
	"table.pagination.goto_start": "Go to first page",
	"table.pagination.goto_next": "Go to next page",
	"table.pagination.goto_prev": "Go to previous page",
	"user.header.title": "Welcome back,",
	"user.name.unknown": "Mysterious Stranger",
	"search.placeholder": "Try to find something...",
	"search.section.environments": "Environments",
	"search.section.queues": "Queues",
	"search.section.schedules": "Schedules",
	"search.section.suggestions": "Suggestions",
	"search.suggestion.dashboard": "Dashboard",
	"search.suggestion.settings": "Settings/Tokens",
	"search.suggestion.queues": "Queues",
	"search.suggestion.schedules": "Schedules",
	"search.no_results": "Nothing found...",
	"combobox.search.placeholder": "Search...",
	"combobox.search.not_found": "No matches found",
	"environments.select.placeholder": "Select environment",
	"environments.not_ready": "⏰ Multiple environments are not supported yet. Get in touch to make it happen faster!",
	"dashboard.summary.queues.title": "Queues",
	"dashboard.summary.queues.item_count": "{count, plural, =0 {No items} one {1 item} other {{count} items}}",
	"dashboard.summary.queues.queue_count": "{count, plural, =0 {All queues are empty} one {In 1 queue} other {In {count} queues}}",
	"dashboard.summary.errors.count": "{count, plural, =0 {No errors} one {1 error} other {{count} errors}} in last 24h",
	"dashboard.summary.errors.title": "Errors",
	"dashboard.summary.errors.last": "Last at {lastAt, time, medium}",
	"dashboard.summary.errors.no_last": "Everything is running smoothly",
	"dashboard.summary.schedules.count": "{count, plural, =0 {No jobs} one {1 job} other {{count} jobs}}",
	"dashboard.summary.schedules.title": "Scheduled Jobs",
	"dashboard.summary.schedules.next": "Next run {nextRun, date, short} at {nextRun, time, medium}",
	"dashboard.summary.schedules.no_next": "There are no future schedules",
	"dashboard.summary.credits.title": "Credits",
	"dashboard.summary.credits.left": "{count, plural, =0 {no credits left} one {1 credit left} other {{count} credits left}}",
	"dashboard.summary.credits.infinity": "∞ credits",
	"dashboard.summary.credits.plan_unlimited": "You are on the Unlimited plan",
	"dashboard.summary.credits.reset_date": "Resets {days, plural, =0 {<em>today</em>} one {in <em>1</em> day} other {in <em>{days}</em> days}}",
	"dashboard.queue.chart.title": "Queue Stats",
	"dashboard.queue.chart.desc": "Summary of items processed and failed across all queues",
	"dashboard.queue.list.title": "Individual queues",
	"dashboard.queue.list.desc": "Top queues by items waiting to be processed",
	"dashboard.queue.list.item.desc": "{processed} processed, {failed} failed",
	"dashboard.queue.list.empty.title": "Your queues are empty",
	"dashboard.queue.list.empty.desc": "Generate an API key in <link>Settings</link> and push items with our SDKs",
	"dropdown.account": "Account",
	"dropdown.misc": "Misc",
	"dropdown.landing": "Home Page",
	"logout": "Sign Out",
	"login.title": "Sign In / Register",
	"login.email": "Email",
	"login.email_notice": "There's no password required. We'll send you an email with magic link to login/register.",
	"login.email_sent": "Check you inbox for a message from us. Click on a <em>magic link</em> there to log in.",
	"login.email_reset": "Back",
	"login.error_invite_only": "Sorry, we're invite only right now",
	"login.error_invite_only.desc": "Get in touch to join waitlist",
	"login.desc": "Sign in to your account or register a new one. Don't worry, we'll figure it out.",
	"login.oauth.header": "Sign in with",
	"login.email.header": "Or continue with",
	"login.email.title": "Email",
	"login.submit": "Continue",
	"settings.api_tokens.title": "API Access Tokens",
	"settings.api_tokens.header": "Pass it in the Authorization header as Bearer token for each request. This way we know it's really you.",
	"settings.api_tokens.id": "Token Id",
	"settings.api_tokens.name": "Name",
	"settings.api_tokens.expires": "Expires",
	"settings.api_tokens.expire_never": "Never",
	"settings.api_tokens.actions": "Actions",
	"settings.api_tokens.create": "Create New",
	"settings.api_tokens.create.title": "Create New Access Token",
	"settings.api_tokens.create.desc": "Generate an access token to use in your applications. Token will grant full access to all your data.",
	"settings.api_tokens.create.name": "Token Name",
	"settings.api_tokens.create.name_placeholder": "Optional",
	"settings.api_tokens.create.expire_never": "Never expires",
	"settings.api_tokens.create.expire_default": "By default tokens expire in 1 year",
	"settings.api_tokens.create.generate": "Generate",
	"settings.api_tokens.create.generated": "Generated token",
	"settings.api_tokens.create.generated_notice": "Please copy the token now! For security reasons you won't be able to see it again.",
	"settings.api_tokens.empty.title": "Create your first API token",
	"settings.api_tokens.empty.desc": "You need some API tokens to use our service. Create one now!",
	"settings.sign_secret.title": "Request Signature Secret",
	"settings.sign_secret.header": "We'll use this secret to sign every request sent to your backend. Use our SDKs to verify the signature.",
	"settings.sign_secret.show": "show",
	"settings.sign_secret.hide": "hide",
	"confirm.delete.title": "Delete selected item?",
	"confirm.delete.desc": "This action cannot be undone. Please make sure you are deleting the right item.",
	"confirm.delete.ok": "Delete",
	"queue.list.empty.title": "Your queues are empty",
	"queue.list.empty.desc": "Generate an API key in <link>Settings</link> and push items with our SDKs",
	"queue.list.item.desc": "{size} queued, {processed} processed, {failed} failed, {running, plural, =0 {0 running} other {<em>{running} running</em>}}",
	"queue.list.item.tooltip": "{size} queued, {processed} processed, {failed} failed, {running} running",
	"queue.details.menu.stats": "Stats",
	"queue.details.menu.items": "Items",
	"queue.details.menu.history": "History",
	"queue.details.menu.live": "Live View",
	"queue.details.waiting": "Waiting",
	"queue.details.queued": "Queued",
	"queue.details.running": "Running",
	"queue.details.failed": "Total failed",
	"queue.details.completed": "Total processed",
	"queue.details.today.completed": "Processed today",
	"queue.details.today.failed": "Failed today",
	"queue.details.chart.title": "Last {days} days",
	"queue.pause.confirm": "Pause this queue?",
	"queue.pause.confirm.desc": "Queue will stop processing items until you resume it.",
	"queue.resume.confirm": "Resume this queue?",
	"queue.resume.confirm.desc": "Queue will immediately start processing all queued items.",
	"queue.item.id": "Id",
	"queue.item.error": "Last error",
	"queue.item.state": "State",
	"queue.item.created": "Created",
	"queue.item.executed": "Executed",
	"queue.item.run_at": "Run At",
	"queue.item.retry_at": "Retry At",
	"queue.item.started": "Started",
	"queue.item.queued": "Queued",
	"queue.item.key": "Unique Key",
	"queue.item.delay": "Delay",
	"queue.item.not_started": "Not Started",
	"queue.item.tries": "Tries",
	"queue.item.payload": "Payload ({type})",
	"queue.item.result": "Result ({type})",
	"queue.item.schedule": "From schedule",
	"queue.item.state.PENDING": "Pending",
	"queue.item.state.RUNNING": "Running",
	"queue.item.state.RETRY": "Retry",
	"queue.item.state.COMPLETED": "Completed",
	"queue.item.state.FAILED": "Failed",
	"schedule.list.empty.title": "You have no schedules",
	"schedule.list.empty.desc": "Generate an API key in <link>Settings</link> and push items with our SDKs",
	"schedule.basic.every.seconds": "Every {every, plural, second {1 item} other {{every} seconds}}",
	"schedule.basic.every.minutes": "Every {every, plural, one {1 minute} other {{every} minutes}}",
	"schedule.basic.every.hours": "Every {every, plural, one {1 hour} other {{every} hours}}",
	"schedule.basic.every.days": "Every {every, plural, one {1 day} other {{every} days}}",
	"schedule.basic.every.months": "Every {every, plural, one {1 month} other {{every} months}}",
	"schedule.basic.every.years": "Every {every, plural, one {1 year} other {{every} years}}",
	"schedule.details.menu.main": "Details",
	"schedule.details.menu.runs": "Runs",
	"schedule.details.id": "Id",
	"schedule.details.key": "Unique Key",
	"schedule.details.schedule": "Schedule",
	"schedule.details.nextRun": "Next Run",
	"schedule.details.lastRun": "Last Run",
	"schedule.details.timezone": "Time zone",
	"schedule.details.payload": "Payload",
	"schedule.details.queue": "Target queue",
	"schedule.details.simulation": "Future Runs",
	"schedule.pause.confirm": "Pause this schedule?",
	"schedule.pause.confirm.desc": "Schedule will stop running until you resume it.",
	"schedule.resume.confirm": "Resume this schedule?",
	"schedule.resume.confirm.desc": "Schedule will immediately start running on its schedule.",
	"schedule.run.id": "Id",
	"schedule.run.queue": "Queue",
	"schedule.run.started": "Started",
	"schedule.run.finished": "Finished",
	"schedule.run.payload": "Payload",
	"schedule.run.result": "Result",
	"schedule.run.error": "Error",
	"schedule.run.state": "State",
	"schedule.run.key": "Unique Key",
	"schedule.run.executed": "Executed",
	"schedule.run.state.COMPLETED": "Completed",
	"schedule.run.state.FAILED": "Failed",
	"--": "--"
}
