import plugin from 'tailwindcss/plugin'

const radialGradientPlugin = plugin(
	({ matchUtilities, theme }) => {
		matchUtilities(
			{
				// map to bg-radient-[*]
				'bg-radient': value => ({
					'background-image': `radial-gradient(${value},var(--tw-gradient-stops))`,
				}),
			},
			{ values: theme('radialGradients') }
		)
	},
	{
		theme: {
			radialGradients: (() => {
				const shapes = ['circle', 'ellipse']
				const pos = {
					c: 'center',
					t: 'top',
					b: 'bottom',
					l: 'left',
					r: 'right',
					tl: 'top left',
					tr: 'top right',
					bl: 'bottom left',
					br: 'bottom right',
				}
				let result = {}
				for (const shape of shapes)
					for (const [posName, posValue] of Object.entries(pos))
						result[`${shape}-${posName}`] = `${shape} at ${posValue}`

				return result
			})(),
		},
	}
)

/** @type {import('tailwindcss').Config} */
export default {
	darkMode: ['class'],
	content: ['./src/**/*.{ts,tsx}'],
	theme: {
		container: {
			center: true,
			padding: '2rem',
			screens: {
				'2xl': '1400px',
			},
		},
		extend: {
			colors: {
				border: 'var(--border)',
				input: 'var(--input)',
				ring: 'var(--ring)',
				background: 'var(--background)',
				foreground: 'var(--foreground)',
				primary: {
					DEFAULT: 'var(--primary)',
					foreground: 'var(--primary-foreground)',
				},
				secondary: {
					DEFAULT: 'var(--secondary)',
					foreground: 'var(--secondary-foreground)',
				},
				destructive: {
					DEFAULT: 'var(--destructive)',
					foreground: 'var(--destructive-foreground)',
				},
				muted: {
					DEFAULT: 'var(--muted)',
					foreground: 'var(--muted-foreground)',
				},
				accent: {
					DEFAULT: 'var(--accent)',
					foreground: 'var(--accent-foreground)',
				},
				popover: {
					DEFAULT: 'var(--popover)',
					foreground: 'var(--popover-foreground)',
				},
				card: {
					DEFAULT: 'var(--card)',
					foreground: 'var(--card-foreground)',
				},
				chartPrimary: {
					DEFAULT: 'var(--chart-primary)',
				},
			},
			borderRadius: {
				lg: 'var(--radius)',
				md: 'calc(var(--radius) - 2px)',
				sm: 'calc(var(--radius) - 4px)',
			},
			keyframes: {
				'accordion-down': {
					from: { height: 0 },
					to: { height: 'var(--radix-accordion-content-height)' },
				},
				'accordion-up': {
					from: { height: 'var(--radix-accordion-content-height)' },
					to: { height: 0 },
				},
			},
			animation: {
				'accordion-down': 'accordion-down 0.2s ease-out',
				'accordion-up': 'accordion-up 0.2s ease-out',
			},
		},
	},
	plugins: [require('tailwindcss-animate'), radialGradientPlugin],
}
