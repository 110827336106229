import { FC } from 'react'
import { cn } from '~/utils/cls'

const Skeleton: FC<React.HTMLAttributes<HTMLDivElement>> = ({
	className,
	...props
}) => {
	return (
		<div
			className={cn('animate-pulse rounded-md bg-gray-100', className)}
			{...props}
		/>
	)
}

export { Skeleton }
