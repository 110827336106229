import { useMutation, useQuery } from '@apollo/client'
import { FC, ReactNode, useCallback } from 'react'
import { graphql } from '~/__gen__/gql'
import { Schedule } from '~/__gen__/gql/graphql'
import { useConfirm } from '~/components/confirm'
import { WithError } from '~/components/error'
import { PauseControl } from '~/components/pause-control'
import { Separator } from '~/components/ui/separator'
import { Translate, useTranslate } from '~/intl'
import { FormattedTimestamp } from '~/intl/formatted'
import { CronLike } from '../cron-like'
import { Simulation } from './simulation'
import { PayloadView } from '~/components/payload-view'
import { Link } from 'wouter'

const ScheduleMainData = graphql(/* GraphQL */ `
	query ScheduleMainData($id: ID!) {
		schedule(id: $id) {
			id
			key
			created
			updated
			queue
			nextRun
			lastRun
			timezone
			payload
			payloadType
			paused
			schedule {
				... on BasicSchedule {
					every
					interval
					startAt
				}
				... on CronSchedule {
					cron
					startAt
				}
			}
			simulation(input: { days: 60, hours: 48, minutes: 30, seconds: 30 }) {
				granularity
				runs
			}
		}
	}
`)

const ConfigureSchedule = graphql(/* GraphQL */ `
	mutation ConfigureSchedule($id: ID!, $config: ScheduleConfigInput!) {
		configureSchedule(id: $id, input: $config) {
			id
			paused
		}
	}
`)

type Props = {
	id: Schedule['id']
	className?: string
}
export const ScheduleDetailsMain: FC<Props> = ({ id, className }) => {
	const { data, error } = useQuery(ScheduleMainData, { variables: { id } })
	const [configureSchedule] = useMutation(ConfigureSchedule)
	const confirm = useConfirm()
	const translate = useTranslate()

	const handlePauseChange = useCallback(
		(pause: boolean) =>
			confirm.destructive(
				() =>
					configureSchedule({
						variables: { id, config: { paused: pause } },
					}),
				{
					title: translate(
						pause ? 'schedule.pause.confirm' : 'schedule.resume.confirm'
					),
					message: translate(
						pause
							? 'schedule.pause.confirm.desc'
							: 'schedule.resume.confirm.desc'
					),
					ok: translate(pause ? 'pause' : 'resume'),
				}
			),
		[confirm, id, configureSchedule, translate]
	)

	const schedule = data?.schedule
	return (
		<WithError error={error} notFound={data && !schedule}>
			{() => (
				<div className={className}>
					<Summary schedule={schedule} onPauseChange={handlePauseChange} />
					<Separator className="my-2" />
					{schedule?.payload && schedule?.payloadType ? (
						<ScheduleDetail labelKey="schedule.details.payload">
							<div className="mt-1">
								<PayloadView
									mimeType={schedule.payloadType}
									payload={schedule.payload}
								/>
							</div>
						</ScheduleDetail>
					) : null}
					{schedule?.simulation ? (
						<>
							<Separator className="my-4" />
							<h4 className="mb-4">
								<Translate>schedule.details.simulation</Translate>
							</h4>
							<Simulation {...schedule.simulation} />
						</>
					) : null}
				</div>
			)}
		</WithError>
	)
}

const Summary: FC<{
	schedule:
		| Pick<
				Schedule,
				| 'id'
				| 'key'
				| 'schedule'
				| 'queue'
				| 'nextRun'
				| 'lastRun'
				| 'timezone'
				| 'paused'
		  >
		| null
		| undefined
	onPauseChange: (paused: boolean) => unknown
}> = ({ schedule, onPauseChange }) => (
	<div className="flex items-center gap-4">
		<div className="flex-1 space-y-1">
			<ScheduleDetail labelKey="schedule.details.id">
				{schedule?.id}
			</ScheduleDetail>
			<ScheduleDetail labelKey="schedule.details.key">
				{schedule?.key}
			</ScheduleDetail>
			<ScheduleDetail labelKey="schedule.details.schedule">
				<CronLike>{schedule?.schedule}</CronLike>
			</ScheduleDetail>
			<ScheduleDetail labelKey="schedule.details.queue">
				{schedule?.queue ? (
					<Link
						className="underline"
						to={`~/queues/${encodeURIComponent(schedule.queue)}`}
					>
						{schedule.queue}
					</Link>
				) : null}
			</ScheduleDetail>
			<ScheduleDetail labelKey="schedule.details.nextRun">
				<FormattedTimestamp value={schedule?.nextRun} />
			</ScheduleDetail>
			<ScheduleDetail labelKey="schedule.details.lastRun">
				<FormattedTimestamp value={schedule?.lastRun} />
			</ScheduleDetail>
			<ScheduleDetail labelKey="schedule.details.timezone">
				{schedule?.timezone}
			</ScheduleDetail>
		</div>
		<PauseControl paused={schedule?.paused} onToggle={onPauseChange} />
	</div>
)

const ScheduleDetail: FC<{ labelKey: string; children: ReactNode }> = ({
	labelKey,
	children,
}) => (
	<div className="flex-1 text-sm">
		<b>
			<Translate>{labelKey}</Translate>
			{': '}
		</b>
		{children}
	</div>
)
