import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'
import { FC, useMemo, useState } from 'react'
import { Button } from '~/components/ui/button'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '~/components/ui/command'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '~/components/ui/popover'
import { Translate, useTranslate } from '~/intl'
import { cn } from '~/utils/cls'

type ComboOption = {
	value: string
	label: string
}
type Props = {
	items: ComboOption[]
	onChange: (value: string) => void
	emptyLabel?: string
	selected: string
	className?: string
	children?: React.ReactNode
}

export const Combobox: FC<Props> = ({
	items,
	children,
	onChange,
	selected,
	emptyLabel,
	className,
}) => {
	const translate = useTranslate()
	const [open, setOpen] = useState(false)
	const value = useMemo(
		() => items.find(v => v.value === selected),
		[items, selected]
	)

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild className={className}>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="min-w-[200px] justify-between"
				>
					{value ? value.label : emptyLabel}
					<CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[200px] p-0">
				<Command>
					<CommandInput
						placeholder={translate('combobox.search.placeholder')}
					/>
					<CommandList>
						<CommandEmpty>
							<Translate>combobox.search.not_found</Translate>
						</CommandEmpty>
						<CommandGroup>
							{items.map(item => (
								<CommandItem
									key={item.value}
									value={item.value}
									onSelect={currentValue => {
										onChange(currentValue)
										setOpen(false)
									}}
								>
									<CheckIcon
										className={cn(
											'mr-2 h-4 w-4',
											selected === item.value ? 'opacity-100' : 'opacity-0'
										)}
									/>
									{item.label}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
				{children}
			</PopoverContent>
		</Popover>
	)
}
