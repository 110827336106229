import { PersonIcon } from '@radix-ui/react-icons'
import { FC, useEffect, useState } from 'react'

type Props = {
	src: string | null | undefined
	size: number
}
export const Avatar: FC<Props> = ({ src, size }) => {
	const [error, setError] = useState(false)
	useEffect(() => {
		setError(false)
	}, [src])

	if (!src || error) {
		//TODO: change to some nicer icon (gray filled user icon?)
		return (
			<div
				className="flex items-center justify-center overflow-hidden rounded-full border-2 border-gray-400 p-1 text-gray-400"
				style={{ width: `${size}px`, height: `${size}px` }}
			>
				<PersonIcon width="100%" height="100%" strokeWidth="1px" />
			</div>
		)
	} else {
		return (
			<img
				src={src}
				alt="Avatar"
				className="overflow-hidden rounded-full border-2 border-gray-400"
				onError={() => setError(true)}
				style={{
					width: `${size}px`,
					height: `${size}px`,
				}}
			/>
		)
	}
}
