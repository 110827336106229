type Logger = {
	name: string | undefined
	debug: (...args: unknown[]) => void
	info: (...args: unknown[]) => void
	warn: (...args: unknown[]) => void
	error: (...args: unknown[]) => void
	logger: (name: string) => Logger
}

const enabledLoggers: string[] =
	localStorage
		.getItem('logger.enabled')
		?.split(',')
		.map(s => s.trim()) || []

export const logger = (name: string, parent?: Logger): Logger => {
	const fullName =
		parent && !Object.is(parent, log) ? parent.name + ' | ' + name : name
	const displayName = `[${name}]`
	const enabled = enabledLoggers.includes(name)
	const self: Logger = {
		name: fullName,
		debug: (...args) => enabled && console.debug(displayName, ...args),
		info: (...args) => enabled && console.info(displayName, ...args),
		warn: (...args) => console.warn(displayName, ...args),
		error: (...args) => console.error(displayName, ...args),
		logger: (child: string) => logger(child, self),
	}
	return self
}

declare global {
	interface Window {
		D1_logger: {
			enabled: string[]
			enable: (name: string) => void
		}
	}
}
window.D1_logger = {
	enabled: enabledLoggers,
	enable: (name: string) => {
		enabledLoggers.push(name)
		localStorage.setItem('logger.enabled', enabledLoggers.join(','))
	},
}

export const log = logger('ROOT')
export default logger
