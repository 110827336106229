import { ArchiveIcon } from '@radix-ui/react-icons'
import { FC, ReactNode } from 'react'
import { Translate } from '~/intl'
import { cn } from '~/utils/cls'

export const EmptyState: FC<{
	className?: string
	title?: ReactNode
	desc?: ReactNode
	vertical?: boolean
}> = ({ className, title, desc, vertical }) => {
	return (
		<div
			className={cn(
				'flex items-center justify-center gap-4 text-gray-400',
				{ 'flex-col items-center text-center': vertical },
				className
			)}
		>
			<ArchiveIcon width={32} height={32} />
			<div>
				<div className=" text-xl">
					{title || <Translate>empty.title</Translate>}
				</div>
				<div className=" text-sm">
					{desc || <Translate>empty.desc</Translate>}
				</div>
			</div>
		</div>
	)
}

export const WithEmptyState: FC<{
	hasData: unknown
	children: ReactNode
	emptyState: ReactNode
	className?: string
}> = ({ children, className, hasData, emptyState: empty }) => {
	if (hasData) {
		return children
	} else {
		return empty || <EmptyState className={className} />
	}
}
