export type LoginProvider = 'twitter' | 'google' | 'apple' | 'fb' | 'github'

type LoginOptions = {
	returnTo?: string
}

export type AuthHook = {
	initialized: boolean
	login: (
		method: 'email' | LoginProvider,
		email?: string | null,
		options?: LoginOptions
	) => Promise<unknown>
	logout: () => Promise<unknown>
	user: User | null
	getToken: () => Promise<string | null>
	refresh: () => Promise<string | null>
}

export type User = {
	readonly displayName: string | null
	readonly email: string | null
	readonly photoURL: string | null
	readonly providerId: string
	readonly uid: string
}
