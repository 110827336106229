import { FC, Fragment, PropsWithChildren, useMemo } from 'react'
import * as intl from 'react-intl'
import en from './en.json'
import { useIntl } from 'react-intl'

const TRANSLATIONS = {
	en,
}

export { Em, Translate, useTranslate } from './translate.js'

declare global {
	interface Navigator {
		userLanguage?: string
		browserLanguage?: string
	}
}

export const IntlProvider: FC<PropsWithChildren> = ({ children }) => {
	const locale = getLocale()

	const mergedMessages = useMemo(() => {
		const localeNoRegion = locale.toLowerCase().split(/[_-]+/)[0]
		return (
			TRANSLATIONS[locale as keyof typeof TRANSLATIONS] ||
			TRANSLATIONS[localeNoRegion as keyof typeof TRANSLATIONS] ||
			TRANSLATIONS[DEFAULT_LOCALE]
		)
	}, [locale])

	return (
		<intl.IntlProvider
			textComponent={Fragment}
			locale={locale}
			messages={mergedMessages}
		>
			{children}
		</intl.IntlProvider>
	)
}

const DEFAULT_LOCALE = 'en'
const getLocale = (): string => {
	if (navigator.languages && navigator.languages.length) {
		return navigator.languages[0]
	}

	return (
		navigator.userLanguage ||
		navigator.language ||
		navigator.browserLanguage ||
		DEFAULT_LOCALE
	)
}

export const useLocale = (): Intl.Locale => {
	const intl = useIntl()
	return useMemo(() => new Intl.Locale(intl.locale), [intl.locale])
}

type WeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7
type WeekInfo = {
	firstDay: WeekDay
	weekend: WeekDay[]
}
const guessWeekInfo = (localeName: string): WeekInfo => {
	const locale = new Intl.Locale(localeName)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const fromMethod = (locale as any).getWeekInfo?.()
	if (fromMethod) return fromMethod

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const fromAccessor = (locale as any).weekInfo
	if (fromAccessor) return fromAccessor

	if (locale.language === 'en' && locale.region === 'US') {
		return { firstDay: 7, weekend: [6, 7] }
	}

	return { firstDay: 1, weekend: [6, 7] }
}
export const useWeekInfo = (): WeekInfo => {
	const intl = useIntl()
	return useMemo(() => guessWeekInfo(intl.locale), [intl.locale])
}
