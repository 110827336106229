import { LayersIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'
import React, { FC, useMemo } from 'react'
import {
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import { Link } from 'wouter'
import { Queue } from '~/__gen__/gql/graphql'
import { EmptyState, WithEmptyState } from '~/components/empty-state'
import { WithLoading } from '~/components/loading'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import { Skeleton } from '~/components/ui/skeleton'
import { Translate } from '~/intl'
import * as queues from '~/queues'
import { colors } from '~/style'
import { cn } from '~/utils/cls'

type QueueStats = {
	date: Date
	completed: number
	failed: number
}
type QueueDetails = Pick<
	Queue,
	'id' | 'displayName' | 'size' | 'failed' | 'processed' | 'paused'
>
type Props = {
	loading?: boolean
	stats?: QueueStats[]
	queues?: QueueDetails[]
}
export const Queues: FC<Props> = ({ loading, stats, queues }) => {
	return (
		<div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
			<QueueChart className="h-[465px]" loading={loading} stats={stats} />
			<QueueList className="h-[465px]" loading={loading} items={queues} />
		</div>
	)
}

const QueueChart: FC<{
	className?: string
	loading?: boolean
	stats?: QueueStats[]
}> = React.memo(({ className, loading, stats }) => {
	const data = useMemo(() => {
		return stats?.map(stat => ({
			date: format(stat.date, 'dd MMM'),
			completed: stat.completed,
			failed: stat.failed,
		}))
	}, [stats])

	return (
		<Card className={cn(className, 'flex flex-col')}>
			<CardHeader>
				<CardTitle>
					<Translate>dashboard.queue.chart.title</Translate>
				</CardTitle>
				<CardDescription>
					<Translate>dashboard.queue.chart.desc</Translate>
				</CardDescription>
			</CardHeader>
			<CardContent>
				<WithLoading loading={loading} placeholder={PlaceholderChart}>
					{() => (
						<ResponsiveContainer width="100%" height={350}>
							<LineChart
								data={data}
								margin={{
									top: 10,
									right: 20,
									left: 0,
									bottom: 5,
								}}
							>
								<XAxis dataKey="date" fontSize={12} />
								<YAxis fontSize={12} />
								<Tooltip contentStyle={{ fontSize: '12px' }} />
								<Line
									type="monotone"
									dataKey="completed"
									stroke={colors.primary}
									strokeWidth={2}
									activeDot={{ r: 8 }}
								/>
								<Line
									type="monotone"
									dataKey="failed"
									stroke={colors.destructive}
									strokeWidth={2}
									activeDot={{ r: 8 }}
								/>
							</LineChart>
						</ResponsiveContainer>
					)}
				</WithLoading>
			</CardContent>
		</Card>
	)
})

const QueueList: FC<{
	className?: string
	loading?: boolean
	items?: QueueDetails[]
}> = ({ className, loading, items }) => {
	return (
		<Card
			className={cn('flex flex-col', className)}
			style={{ minHeight: '350px' }}
		>
			<CardHeader>
				<CardTitle>
					<Translate>dashboard.queue.list.title</Translate>
				</CardTitle>
				<CardDescription>
					<Translate>dashboard.queue.list.desc</Translate>
				</CardDescription>
			</CardHeader>
			<WithLoading loading={loading} placeholder={PlaceholderList}>
				{() => (
					<WithEmptyState
						hasData={items?.length}
						emptyState={<ListEmptyState />}
					>
						<CardContent className="min-h-0 space-y-8 overflow-auto">
							{items?.map(queue => (
								<Link
									key={queue.id}
									href={`/queues/${encodeURIComponent(queue.id)}`}
									className="flex items-center"
								>
									<LayersIcon width="32px" height="32px" />
									<div className="flex-column ml-4 items-center space-y-1">
										<p className="overflow-clip overflow-ellipsis align-middle text-sm font-medium leading-4">
											{queues.displayName(queue)}
											{queue.paused ? (
												<span className="text-muted-foreground pl-1 font-bold ">
													[<Translate>paused</Translate>]
												</span>
											) : null}
										</p>
										<p className="text-muted-foreground align-middle text-sm leading-4">
											<Translate
												processed={queue.processed}
												failed={queue.failed}
											>
												dashboard.queue.list.item.desc
											</Translate>
										</p>
									</div>
									<div className="ml-auto font-medium">{queue.size}</div>
								</Link>
							))}
						</CardContent>
					</WithEmptyState>
				)}
			</WithLoading>
		</Card>
	)
}

const PlaceholderChart = (): JSX.Element => {
	return <Skeleton className="h-full w-full rounded-md" />
}

const PlaceholderList = (): JSX.Element => {
	return (
		<CardContent className="min-h-0 space-y-8 overflow-auto">
			{[1, 2, 3].map(i => (
				<div key={'placeholder-' + i} className="flex items-center space-x-4">
					<Skeleton
						className="rounded-md"
						style={{ width: '32px', height: '32px' }}
					/>
					<div className="space-y-1">
						<Skeleton className="h-4 w-[250px]" />
						<Skeleton className="h-4 w-[200px]" />
					</div>
				</div>
			))}
		</CardContent>
	)
}

const ListEmptyState = (): JSX.Element => (
	<EmptyState
		className="pb-8 pt-20"
		vertical
		title={<Translate>dashboard.queue.list.empty.title</Translate>}
		desc={
			<Translate
				link={text => (
					<Link className="font-bold hover:underline" href="/settings">
						{text}
					</Link>
				)}
			>
				dashboard.queue.list.empty.desc
			</Translate>
		}
	/>
)
