import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config.js'
import { FC, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

const fullConfig = resolveConfig(tailwindConfig)

export const theme = fullConfig
export const breakpoints = fullConfig.theme.screens
export const colors = {
	background: '#ffffff',
	foreground: '#030712',
	card: '#ffffff',
	cardForeground: '#030712',
	popover: '#ffffff',
	popoverForeground: '#030712',
	primary: '#7c3aed',
	primaryForeground: '#f9fafb',
	secondary: '#f3f4f6',
	secondaryForeground: '#111827',
	muted: '#f3f4f6',
	mutedForeground: '#6b7280',
	accent: '#f3f4f6',
	accentForeground: '#111827',
	destructive: '#ef4444',
	destructiveForeground: '#f9fafb',
	border: '#e5e7eb',
	input: '#e5e7eb',
	ring: '#7c3aed',
	chartPrimary: 'rgb(156 163 175)',
}

export const MobileVsDesktop: FC<{
	mobile: ReactNode
	desktop: ReactNode
}> = ({ mobile, desktop }) => {
	return (
		<LargeVsSmall large={desktop} small={mobile} breakpoint={breakpoints.sm} />
	)
}

export const LargeVsSmall: FC<{
	large: ReactNode
	small: ReactNode
	breakpoint: number | string
}> = ({ large, small, breakpoint }) => {
	const isSmall = useMediaQuery({ query: `(max-width: ${breakpoint})` })
	if (isSmall) {
		return small
	} else {
		return large
	}
}
