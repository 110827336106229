import { FC } from 'react'
import { useQuery } from '@apollo/client'
import { graphql } from '~/__gen__/gql'
import { ErrorPanel } from '~/components/error'
import { Queues } from './queues'
import { Summary } from './summary'

const DashboardData = graphql(/* GraphQL */ `
	query DashboardData {
		queues {
			id
			displayName
			paused
			size
			failed
			processed
		}
		stats {
			queues
			pending
			schedules
			nextSchedule
			failedToday
			lastFailedAt
		}
		histogram {
			date
			completed
			failed
		}
	}
`)

export const Dashboard: FC = () => {
	const { loading, error, data } = useQuery(DashboardData)

	if (error) return <ErrorPanel />
	const stats = data?.stats

	return (
		<div className="flex flex-col gap-4">
			<Summary
				loading={loading}
				pending={stats?.pending || 0}
				queues={stats?.queues || 0}
				schedules={stats?.schedules || 0}
				nextSchedule={stats?.nextSchedule}
				errorsToday={stats?.failedToday || 0}
				lastErrorAt={stats?.lastFailedAt}
			/>
			<Queues loading={loading} queues={data?.queues} stats={data?.histogram} />
		</div>
	)
}
