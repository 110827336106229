import { FC, PropsWithChildren } from 'react'
import { Badge } from './ui/badge'
import { cn } from '~/utils/cls'
import { Link, useRoute } from 'wouter'

export const SubMenuItem: FC<
	PropsWithChildren<{ href: string; match?: string }>
> = ({ href, match, children }) => {
	const [isActive] = useRoute(match || href)
	return (
		<Link href={href}>
			<Badge
				className={cn(
					'cursor-pointer bg-gray-400 transition-transform hover:scale-105 hover:shadow-md',
					{ 'bg-gray-800': isActive }
				)}
			>
				{children}
			</Badge>
		</Link>
	)
}

export const SubMenu: FC<PropsWithChildren<{ className?: string }>> & {
	Item: typeof SubMenuItem
} = ({ children, className }) => {
	return (
		<div className={cn('flex items-center gap-1', className)}>{children}</div>
	)
}
SubMenu.Item = SubMenuItem
