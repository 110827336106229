import { FC, ReactNode, createContext, useContext } from 'react'
import { Redirect, useLocation } from 'wouter'
import { AuthHook } from './models'
import { Loading } from '~/components/loading'

const AuthContext = createContext<AuthHook | null>(null)

export const AuthProvider: FC<{
	children?: ReactNode | ((auth: AuthHook) => ReactNode)
	auth: AuthHook
}> = ({ children, auth }) => {
	return (
		<AuthContext.Provider value={auth}>
			{typeof children === 'function' ? children(auth) : children}
		</AuthContext.Provider>
	)
}
export const useAuth = (): AuthHook => {
	const auth = useContext(AuthContext)
	if (auth) {
		return auth
	} else {
		throw new Error('Auth context not initialized')
	}
}

export const RequireLogin: FC<{
	children?: ReactNode | ((auth: AuthHook) => ReactNode)
}> = ({ children }) => {
	const [location] = useLocation()
	const auth = useAuth()

	if (auth.user) {
		return typeof children === 'function' ? children(auth) : children
	} else if (auth.initialized) {
		return <Redirect to="/login" state={{ returnTo: location }} />
	} else {
		return (
			<div className="flex h-svh w-svw items-center justify-center">
				<Loading />
			</div>
		)
	}
}
