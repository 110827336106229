import { FC } from 'react'
import { useTranslate } from '~/intl'
import { CodeBlock } from './code-block'

export const PayloadView: FC<{
	mimeType?: string | null
	payload?: Uint8Array | number[] | string | null | undefined
}> = ({ mimeType, payload }) => {
	const translate = useTranslate()
	if (!mimeType || !payload || payload.length === 0) {
		return <CodeBlock mimeType="text/plain" code={translate('code.empty')} />
	} else if (CodeBlock.isSupported(mimeType)) {
		return <CodeBlock mimeType={mimeType} code={payload} />
	} else {
		return <CodeBlock mimeType="text/plain" code={translate('code.binary')} />
	}
}
