import { Link, LinkProps, useRoute } from 'wouter'

import { FC, ReactNode } from 'react'
import { cn } from '~/utils/cls'

export const TabsMenuLink: FC<
	LinkProps & { className?: string; href: string }
> = ({ className, children, ...props }) => {
	const [isActive] = useRoute(props.href + '(/.*)?')

	return (
		<Link {...props} asChild={true}>
			<a
				className={cn(
					'ring-offset-background focus-visible:ring-ring data-[state=active]:bg-background data-[state=active]:text-foreground inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:shadow',
					className
				)}
				data-state={isActive ? 'active' : 'inactive'}
			>
				{children}
			</a>
		</Link>
	)
}

export const TabsMenu: FC<{
	className?: string
	children?: ReactNode
}> & { Link: typeof TabsMenuLink } = ({ children, className }) => {
	return (
		<nav
			className={cn(
				'bg-muted text-muted-foreground inline-flex h-9 items-center justify-center rounded-lg p-1',
				className
			)}
		>
			{children}
		</nav>
	)
}
TabsMenu.Link = TabsMenuLink
