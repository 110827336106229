import React, { FC, ReactNode } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from './ui/card'

type Props = {
	title: ReactNode
	icon?: ReactNode
	children: ReactNode
}
export const SummaryCard: FC<Props> = ({ children, icon, title }) => {
	return (
		<Card className="overflow-hidden">
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				<CardTitle className="overflow-clip overflow-ellipsis text-sm font-medium">
					{title}
				</CardTitle>
				<div className="text-gray-500">{icon}</div>
			</CardHeader>
			<CardContent>{children}</CardContent>
		</Card>
	)
}

export default SummaryCard
