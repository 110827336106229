import type { FormatXMLElementFn } from 'intl-messageformat'
import { FC, useCallback } from 'react'
import { FormattedMessage, PrimitiveType, useIntl } from 'react-intl'

type BundleValue =
	| PrimitiveType
	| FormatXMLElementFn<React.ReactNode, React.ReactNode>
export type BundleValues = Record<string, BundleValue>

type ChunksFormatter = (chunks: string | React.ReactNode[]) => JSX.Element
type Props = Record<string, BundleValue> & {
	children: string
}

type TranslateFn = (id?: string, values?: BundleValues) => string
export const useTranslate = (): TranslateFn => {
	const intl = useIntl()
	return useCallback(
		(id?: string, values?: BundleValues): string => {
			return intl.formatMessage({ id }, values)?.toString() || ''
		},
		[intl]
	)
}

export const Translate: FC<Props> = ({ children, ...rest }) => (
	<FormattedMessage id={children} values={{ em: Em, ...rest }} />
)

export const Em: ChunksFormatter = chunks => <em>{chunks}</em>

export default Translate
