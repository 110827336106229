import { useLazyQuery } from '@apollo/client'
import {
	CalendarIcon,
	DashboardIcon,
	DesktopIcon,
	GearIcon,
	LayersIcon,
} from '@radix-ui/react-icons'
import { CommandLoading } from 'cmdk'
import { detect as detectBrowser } from 'detect-browser'
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'wouter'
import { graphql } from '~/__gen__/gql'
import { Translate, useTranslate } from '~/intl'
import * as queues from '~/queues/models'
import * as schedules from '~/schedules/models'
import { Loading } from './loading'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from './ui/command'

const browser = detectBrowser()
const isMac = browser?.os === 'Mac OS'
const isMobile = browser?.os === 'iOS' || browser?.os === 'Android OS'

const environments: Array<{ name: string; key: string }> = [
	// {
	// 	name: 'Production',
	// 	key: 'production',
	// },
	// {
	// 	name: 'Staging',
	// 	key: 'staging',
	// },
	// {
	// 	name: 'Development',
	// 	key: 'development',
	// },
]

//TODO: add environments
const QueuesSchedulesAndEnvironments = graphql(/* GraphQL */ `
	query QueuesSchedulesAndEnvironments {
		queues {
			id
			displayName
		}
		schedules {
			id
			key
		}
	}
`)

export const SearchInput: FC = () => {
	const translate = useTranslate()
	const inputRef = useRef<HTMLInputElement>(null)
	const selfRef = useRef<HTMLDivElement>(null)
	const [value, setValue] = useState('')
	const [menuVisible, setMenuVisible] = useState(false)
	const [_, navigate] = useLocation()

	const [fetchData, { loading, data }] = useLazyQuery(
		QueuesSchedulesAndEnvironments
	)

	const resetState = useCallback((): void => {
		setValue('')
		setMenuVisible(false)
	}, [])
	const navigateTo = (path: string) => () => {
		resetState()
		navigate(path)
	}

	const handleEsc = useCallback(
		(e: KeyboardEvent): void => {
			if (e.key === 'Escape') {
				resetState()
			}
		},
		[resetState]
	)

	useEffect(() => {
		const handleCmdK = (e: KeyboardEvent): void => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				inputRef.current?.focus()
			}
		}
		window.addEventListener('keydown', handleCmdK)
		return () => window.removeEventListener('keydown', handleCmdK)
	}, [])

	useEffect(() => {
		if (menuVisible) {
			const hideIfClickOutside = (e: MouseEvent): void => {
				if (selfRef.current && !e.composedPath().includes(selfRef.current)) {
					resetState()
				}
			}
			window.addEventListener('keydown', handleEsc)
			window.addEventListener('click', hideIfClickOutside)
			return () => {
				window.removeEventListener('keydown', handleEsc)
				window.removeEventListener('click', hideIfClickOutside)
			}
		}
	}, [resetState, handleEsc, menuVisible])

	useEffect(() => {
		if (menuVisible) {
			fetchData()
		}
	}, [menuVisible, fetchData])

	return (
		<Command
			className="relative overflow-visible rounded-3xl border"
			ref={selfRef}
		>
			<CommandInput
				name="search"
				className="border-none"
				placeholder={
					translate('search.placeholder') +
					(isMobile ? '' : isMac ? ' (⌘K)' : ' (Ctrl+K)')
				}
				onKeyDown={e => {
					if (e.key === 'Escape') {
						resetState()
					} else if (!menuVisible) {
						setMenuVisible(true)
					}
				}}
				onFocus={useCallback((): void => {
					setMenuVisible(true)
				}, [])}
				onBlur={resetState}
				autoComplete="off"
				autoCorrect="off"
				inputMode="text"
				autoCapitalize="off"
				value={value}
				onValueChange={setValue}
				ref={inputRef}
			/>
			<CommandList
				className="bg-card text-card-foreground absolute left-0 right-0 top-12 z-50 scroll-pb-2 rounded-xl border shadow"
				hidden={!menuVisible}
			>
				<CommandEmpty>
					<Translate>search.no_results</Translate>
				</CommandEmpty>
				<CommandGroup heading={translate('search.section.suggestions')}>
					<Item onSelect={navigateTo('/')}>
						<DashboardIcon strokeWidth="1px" className="mr-2 h-4 w-4" />
						<span>
							<Translate>search.suggestion.dashboard</Translate>
						</span>
					</Item>
					<Item onSelect={navigateTo('/queues')}>
						<LayersIcon strokeWidth="1px" className="mr-2 h-4 w-4" />
						<span>
							<Translate>search.suggestion.queues</Translate>
						</span>
					</Item>
					<Item onSelect={navigateTo('/schedules')}>
						<CalendarIcon strokeWidth="1px" className="mr-2 h-4 w-4" />
						<span>
							<Translate>search.suggestion.schedules</Translate>
						</span>
					</Item>
					<Item onSelect={navigateTo('/settings')}>
						<GearIcon strokeWidth="1px" className="mr-2 h-4 w-4" />
						<span>
							<Translate>search.suggestion.settings</Translate>
						</span>
					</Item>
				</CommandGroup>
				{environments.length ? (
					<CommandGroup heading={translate('search.section.environments')}>
						{environments.map(env => (
							<Item key={env.key}>
								<DesktopIcon strokeWidth="1px" className="mr-2 h-4 w-4" />
								<span>{env.name}</span>
							</Item>
						))}
					</CommandGroup>
				) : null}
				{data?.queues ? (
					<CommandGroup heading={translate('search.section.queues')}>
						{data?.queues.map(queue => (
							<Item
								key={queue.id}
								onSelect={navigateTo(`/queues/${encodeURIComponent(queue.id)}`)}
							>
								<LayersIcon strokeWidth="1px" className="mr-2 h-4 w-4" />
								<span>{queues.displayName(queue)}</span>
							</Item>
						))}
					</CommandGroup>
				) : null}
				{data?.schedules ? (
					<CommandGroup heading={translate('search.section.schedules')}>
						{data?.schedules.map(schedule => (
							<Item
								key={schedule.id}
								onSelect={navigateTo(`/schedules/${schedule.id}`)}
							>
								<CalendarIcon strokeWidth="1px" className="mr-2 h-4 w-4" />
								<span>{schedules.displayName(schedule)}</span>
							</Item>
						))}
					</CommandGroup>
				) : null}
				<CommandLoading>
					{loading ? <Loading size={24} className="m-auto my-2" /> : null}
				</CommandLoading>
			</CommandList>
		</Command>
	)
}
const Item: FC<{
	onSelect?: () => unknown
	children: ReactNode
}> = ({ onSelect, children }) => {
	return (
		<CommandItem onSelect={onSelect} onMouseDown={onSelect}>
			{children}
		</CommandItem>
	)
}
