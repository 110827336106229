import { FC, ReactNode } from 'react'
import { Translate } from '~/intl'
import { Button } from './ui/button'
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from './ui/dialog'

type Props = {
	title: ReactNode
	description?: ReactNode
	children: ReactNode
	onCancel: (withButton: boolean) => void
	ok?: ReactNode
	cancel?: ReactNode
	onOk?: () => void
	modal?: boolean
	disabled?: boolean
	className?: string
}

export const PopupDialog: FC<Props> = ({
	onCancel,
	title,
	description,
	children,
	onOk,
	ok,
	cancel,
	modal,
	disabled,
	className,
}) => (
	<Dialog
		open={true}
		onOpenChange={() => {
			if (!disabled) {
				onCancel(false)
			}
		}}
		modal={modal}
	>
		<DialogContent className={className}>
			<DialogHeader>
				<DialogTitle>{title}</DialogTitle>
				{description && <DialogDescription>{description}</DialogDescription>}
			</DialogHeader>
			{children}
			<DialogFooter className="mt-3 sm:justify-end">
				<Button
					type="button"
					variant="secondary"
					onClick={() => onCancel(true)}
					disabled={disabled}
				>
					{cancel || <Translate>cancel</Translate>}
				</Button>
				{onOk && (
					<Button onClick={onOk} variant="default" disabled={disabled}>
						{ok || <Translate>ok</Translate>}
					</Button>
				)}
			</DialogFooter>
		</DialogContent>
	</Dialog>
)
