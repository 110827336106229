export const middleTruncate = (
	length: number,
	str: string | null | undefined,
	splitPoint?: number
): string => {
	if (!str) {
		return ''
	} else if (str.length <= length) {
		return str
	} else {
		const leftSize = splitPoint ?? length / 2
		const rightSize = length - leftSize
		return `${str.slice(0, leftSize)}...${str.slice(-rightSize)}`
	}
}

export const truncate = (
	length: number,
	str: string | null | undefined
): string => {
	if (!str) {
		return ''
	} else if (str.length <= length) {
		return str
	} else {
		return `${str.slice(0, length)}...`
	}
}
