import { EnvelopeClosedIcon, GitHubLogoIcon } from '@radix-ui/react-icons'
import {
	CSSProperties,
	FC,
	PropsWithChildren,
	useCallback,
	useEffect,
	useId,
	useState,
} from 'react'
import { useLocation } from 'wouter'
import { useHistoryState } from 'wouter/use-browser-location'
import { Logo } from '~/components/logo'
import { Button } from '~/components/ui/button'
import {
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import { Input } from '~/components/ui/input'
import { Label } from '~/components/ui/label'
import { Translate, useTranslate } from '~/intl'
import { LoginProvider } from '../models'
import { useAuth } from '../react'
import bg from './img/login-bg.jpg'
import GoogleLogo from './img/logo-google.svg'
import { ErrorPanel } from '~/components/error'

export const LoginScreen: FC = () => {
	return (
		<div
			className="flex h-svh w-svw overflow-auto bg-opacity-0 bg-cover bg-center bg-no-repeat p-4 md:bg-[image:var(--image-url)]"
			style={{ '--image-url': `url(${bg})` } as CSSProperties}
		>
			<LoginPanel />
		</div>
	)
}

const LoginPanel: FC = () => {
	const emailId = useId()
	const state = useHistoryState<{ returnTo?: string }>()
	const auth = useAuth()
	const translate = useTranslate()
	const [_, setLocation] = useLocation()
	const [submitted, setSubmitted] = useState(false)
	const [emailSent, setEmailSent] = useState(false)
	const [error, setError] = useState<Error & { code?: string }>()

	useEffect(() => {
		if (auth.initialized && auth.user) {
			setLocation('/')
		}
	}, [auth, setLocation])

	const login = useCallback(
		(provider: LoginProvider) => () => {
			auth.login(provider, null, { returnTo: state?.returnTo })
		},
		[auth, state?.returnTo]
	)

	const loginEmail = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			setSubmitted(true)
			event.preventDefault()
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const email = (event.target as any).email.value
			auth
				.login('email', email, { returnTo: state?.returnTo })
				.then(() => {
					setEmailSent(true)
				})
				.catch(setError)
		},
		[auth, state]
	)

	const [showWaitlistPopup, setShowWaitlistPopup] = useState(false)

	return (
		<div className="bg-card text-card-foreground mx-auto h-fit w-96 md:my-auto md:rounded-xl md:border md:shadow">
			<form onSubmit={loginEmail} onInvalid={() => setSubmitted(true)}>
				<CardHeader className="space-y-1">
					<div className="pb-4">
						<Logo size={78} className="m-auto" />
					</div>
					<CardTitle className="text-2xl">
						<Translate>login.title</Translate>
					</CardTitle>
					<CardDescription className="whitespace-normal">
						<Translate>login.desc</Translate>
					</CardDescription>
				</CardHeader>
				{emailSent ? (
					<>
						<CardContent className="grid gap-4">
							<EnvelopeClosedIcon className="m-auto h-12 w-12 text-gray-400" />
							<div className="border-muted mt-1 rounded-md border bg-green-100 p-2 text-center text-sm">
								📨 <Translate>login.email_sent</Translate>
							</div>
						</CardContent>

						<CardFooter>
							<Button
								variant="secondary"
								className="w-full"
								type="button"
								onClick={() => {
									setEmailSent(false)
									setSubmitted(false)
								}}
							>
								<Translate>login.email_reset</Translate>
							</Button>
						</CardFooter>
					</>
				) : (
					<>
						<CardContent className="grid gap-4">
							<Label>
								<Translate>login.oauth.header</Translate>
							</Label>
							<div className="flex flex-col gap-2">
								<Button
									variant="outline"
									type="button"
									onClick={login('github')}
								>
									<GitHubLogoIcon className="mr-2 h-4 w-4" />
									<ProviderName>Github</ProviderName>
								</Button>
								<Button
									variant="outline"
									type="button"
									onClick={login('google')}
								>
									<GoogleLogo className="mr-2 h-4 w-4" />
									<ProviderName>Google</ProviderName>
								</Button>
							</div>
							<div className="relative">
								<div className="absolute inset-0 flex items-center">
									<span className="w-full border-t" />
								</div>
								<div className="relative flex justify-center text-xs uppercase">
									<span className="bg-background text-muted-foreground px-2">
										<Translate>login.email.header</Translate>
									</span>
								</div>
							</div>
							<div className="grid gap-2">
								<Label htmlFor={emailId}>
									<Translate>login.email</Translate>
								</Label>
								<Input
									id={emailId}
									type="email"
									name="email"
									required
									placeholder="user@example.com"
									data-validate={submitted}
								/>
							</div>
							{error ? (
								<div className="text-sm text-red-500">
									{error.code === 'auth/admin-restricted-operation' ? (
										<ErrorPanel
											error={translate('login.error_invite_only')}
											details={translate('login.error_invite_only.desc')}
										/>
									) : (
										<ErrorPanel />
									)}
								</div>
							) : null}
							<div className="border-muted mt-1 flex gap-2 rounded-md border bg-gray-50 p-2 text-xs text-gray-700">
								<div>📨</div>
								<div>
									<Translate>login.email_notice</Translate>
								</div>
							</div>
							{showWaitlistPopup ? (
								<WaitlistPopup onClose={() => setShowWaitlistPopup(false)} />
							) : null}
						</CardContent>

						<CardFooter>
							<Button className="w-full" type="submit">
								<Translate>login.submit</Translate>
							</Button>
						</CardFooter>
					</>
				)}
			</form>
		</div>
	)
}

const ProviderName: FC<PropsWithChildren> = ({ children }) => (
	<span className="min-w-11 text-start">{children}</span>
)

export const WaitlistPopup: FC<{ onClose: () => unknown }> = ({ onClose }) => {
	return (
		<div className="fixed left-0 top-0 z-40 h-screen w-screen">
			<div
				className="modal-overlay absolute left-0 top-0 z-40 h-full w-full bg-gray-400 opacity-80"
				onClick={onClose}
			></div>

			<div className="absolute left-1/2 top-1/2 z-50 mx-auto h-full w-full -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-lg bg-white md:h-5/6 md:w-[640px]">
				<div
					onClick={onClose}
					className="absolute right-3 top-3 flex h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-white bg-opacity-60 shadow-md transition duration-100 ease-in-out hover:scale-110"
				>
					<svg
						className="fill-current text-gray-700"
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 18 18"
					>
						<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
					</svg>
				</div>
				<iframe
					src="https://docs.google.com/forms/d/e/1FAIpQLScmkPIVtkeNaFSWI2tjyJi6JHDFtNJ6Kf_21KaLXrX-eXUPCw/viewform?embedded=true"
					width="100%"
					height="100%"
					frameBorder={10}
					marginHeight={100}
					marginWidth={100}
				>
					Loading Form…
				</iframe>
			</div>
		</div>
	)
}
