import { useMutation, useQuery } from '@apollo/client'
import { formatDuration, intervalToDuration } from 'date-fns'
import { FC, ReactNode } from 'react'
import { Link } from 'wouter'
import { graphql } from '~/__gen__/gql'
import { QueueHistoryItem, QueueItem } from '~/__gen__/gql/graphql'
import { WithLoading } from '~/components/loading'
import { PayloadView } from '~/components/payload-view'
import { Translate } from '~/intl'
import { FormattedTimestamp } from '~/intl/formatted'
import { BundleValues, useTranslate } from '~/intl/translate'
import * as schedules from '~/schedules/models'
import { isHistory } from '../models'
import { StateBadge } from './state-badge'
import { DotsMenu } from '~/components/dots-menu'
import { DropdownMenuItem } from '~/components/ui/dropdown-menu'
import { useConfirm } from '~/components/confirm'

export const renderQueueItem = (item: Pick<QueueItem, 'id'>): ReactNode => (
	<QueueItemDetails id={item.id} />
)

const FetchFullItem = graphql(/* GraphQL */ `
	query QueueItem($id: ID!) {
		queueItem(id: $id) {
			id
			created
			state
			tries
			delay
			schedule {
				id
				key
			}
			payloadType
			payload
			... on QueueItem {
				started
				resultType
				result
				runAfter
				error
			}
			... on QueueHistoryItem {
				scheduled
				resultType
				result
				error
			}
		}
	}
`)
export const QueueItemDetails: FC<{ id: QueueItem['id'] }> = ({ id }) => {
	const { data, loading, error } = useQuery(FetchFullItem, {
		variables: { id },
	})
	const item = data?.queueItem

	return (
		<WithLoading loading={loading} error={error} notFound={data && !item}>
			{() => {
				if (!item) return null
				if (isHistory(item)) {
					return <HistoryItemDetails item={item} />
				} else {
					return <FutureItemDetails item={item} />
				}
			}}
		</WithLoading>
	)
}

const HistoryItemDetails: FC<{
	item: Pick<
		QueueHistoryItem,
		| 'id'
		| 'created'
		| 'scheduled'
		| 'schedule'
		| 'state'
		| 'tries'
		| 'delay'
		| 'payload'
		| 'payloadType'
		| 'result'
		| 'resultType'
		| 'error'
	>
}> = ({ item }) => {
	const translate = useTranslate()
	return (
		<div className="space-y-1">
			<QueueItemDetail labelKey="queue.item.id">{item.id}</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.executed">
				<FormattedTimestamp value={item.created} />
			</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.queued">
				<FormattedTimestamp value={item.scheduled} />
			</QueueItemDetail>
			{item.schedule ? (
				<QueueItemDetail labelKey="queue.item.schedule">
					<Link className="underline" to={`~/schedules/${item.schedule.id}`}>
						{schedules.displayName(item.schedule)}
					</Link>
				</QueueItemDetail>
			) : null}
			<QueueItemDetail labelKey="queue.item.state">
				<StateBadge state={item.state} />
			</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.tries">
				{item.tries}
			</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.delay">
				{formatDuration(intervalToDuration({ start: 0, end: item.delay || 0 }))}
			</QueueItemDetail>
			<QueueItemDetail
				labelKey="queue.item.payload"
				values={{
					type: item.payloadType ? item.payloadType : translate('none'),
				}}
			>
				<PayloadDetail mimeType={item.payloadType} payload={item.payload} />
			</QueueItemDetail>
			<QueueItemDetail
				labelKey="queue.item.result"
				values={{
					type: item.resultType ? item.resultType : translate('none'),
				}}
			>
				<PayloadDetail mimeType={item.resultType} payload={item.result} />
			</QueueItemDetail>
			{item.error ? (
				<QueueItemDetail labelKey="queue.item.error">
					{item.error}
				</QueueItemDetail>
			) : null}
		</div>
	)
}

const DeleteQueueItem = graphql(/* GraphQL */ `
	mutation DeleteQueueItem($id: ID!) {
		deleteQueueItem(id: $id)
	}
`)
const FutureItemDetails: FC<{
	item: Pick<
		QueueItem,
		| 'id'
		| 'created'
		| 'started'
		| 'state'
		| 'tries'
		| 'delay'
		| 'payload'
		| 'payloadType'
		| 'result'
		| 'resultType'
		| 'error'
		| 'runAfter'
	>
}> = ({ item }) => {
	const translate = useTranslate()
	const confirm = useConfirm()

	const [deleteItem] = useMutation(DeleteQueueItem, {
		variables: { id: item.id },
		refetchQueries: ['QueueItems', 'QueuesData'],
	})

	return (
		<div className="relative space-y-1">
			<DotsMenu className="absolute right-0 top-0">
				<DropdownMenuItem
					onClick={() => confirm.delete(deleteItem)}
					className="text-destructive"
				>
					<Translate>delete</Translate>
				</DropdownMenuItem>
			</DotsMenu>
			<QueueItemDetail labelKey="queue.item.id">{item.id}</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.created">
				<FormattedTimestamp value={item.created} />
			</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.started">
				{item.started ? (
					<FormattedTimestamp value={item.started} />
				) : (
					<Translate>queue.item.not_started</Translate>
				)}
			</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.state">
				<StateBadge state={item.state} />
			</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.tries">
				{item.tries}
			</QueueItemDetail>
			<QueueItemDetail labelKey="queue.item.delay">
				{formatDuration(intervalToDuration({ start: 0, end: item.delay || 0 }))}
			</QueueItemDetail>
			<QueueItemDetail
				labelKey={
					item.state === 'RETRY' ? 'queue.item.retry_at' : 'queue.item.run_at'
				}
			>
				<FormattedTimestamp value={item.runAfter} />
			</QueueItemDetail>
			<QueueItemDetail
				labelKey="queue.item.payload"
				values={{
					type: item.payloadType ? item.payloadType : translate('none'),
				}}
			>
				<PayloadDetail mimeType={item.payloadType} payload={item.payload} />
			</QueueItemDetail>
			{item.result || item.resultType ? (
				<QueueItemDetail
					labelKey="queue.item.result"
					values={{
						type: item.resultType ? item.resultType : translate('none'),
					}}
				>
					<PayloadDetail mimeType={item.resultType} payload={item.result} />
				</QueueItemDetail>
			) : null}
			{item.error ? (
				<QueueItemDetail labelKey="queue.item.error">
					{item.error}
				</QueueItemDetail>
			) : null}
		</div>
	)
}

const PayloadDetail: FC<{
	mimeType?: string | null
	payload?: Uint8Array | number[] | string | null
}> = ({ mimeType, payload }) => (
	<div className="mt-1 overflow-x-auto">
		<PayloadView mimeType={mimeType} payload={payload} />
	</div>
)

const QueueItemDetail: FC<{
	labelKey: string
	values?: BundleValues
	children: ReactNode
	className?: string
}> = ({ labelKey, values, children, className }) => (
	<div className={className}>
		<b>
			<Translate {...values}>{labelKey}</Translate>
		</b>
		: {children}
	</div>
)
