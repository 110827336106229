import { Queue, QueueHistoryItem, QueueItem } from '~/__gen__/gql/graphql'

export const displayName = (
	queue: Pick<Queue, 'id' | 'displayName'>
): string => {
	if (queue.displayName) {
		return queue.displayName
	} else {
		return decodeURIComponent(queue.id)
	}
}

export const isHistory = (
	item: Pick<QueueItem | QueueHistoryItem, 'state'>
): item is QueueHistoryItem => {
	return item.state === 'COMPLETED' || item.state === 'FAILED'
}
