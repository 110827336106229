import 'main.css'

import { FC, useEffect } from 'react'
import { Route, Switch } from 'wouter'
import { IntlProvider } from '~/intl'
import { ErrorBoundary } from 'react-error-boundary'
import { Crisp } from 'crisp-sdk-web'
import {
	AuthProvider,
	LoginScreen,
	RequireLogin,
	useFirebaseAuth,
} from './auth'
import { GraphProvider } from './graph'
import { Routes } from './routes'
import { ErrorScreen } from './components/error'
import { ConfirmProvider } from './components/confirm'
import config from './config'

export const Main: FC = () => {
	const firebaseAuth = useFirebaseAuth()

	useEffect(() => {
		Crisp.configure(config.crisp.websiteId)
	}, [])

	return (
		<IntlProvider>
			<ErrorBoundary FallbackComponent={ErrorScreen}>
				<ConfirmProvider>
					<AuthProvider auth={firebaseAuth}>
						<Switch>
							<Route path="/login" component={LoginScreen} />
							<Route>
								{() => (
									<RequireLogin>
										{() => (
											<GraphProvider>
												<Routes />
											</GraphProvider>
										)}
									</RequireLogin>
								)}
							</Route>
						</Switch>
					</AuthProvider>
				</ConfirmProvider>
			</ErrorBoundary>
		</IntlProvider>
	)
}
