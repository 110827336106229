import { ChevronDownIcon } from '@radix-ui/react-icons'
import { Fragment, ReactNode } from 'react'
import { Link, useRoute, useSearch } from 'wouter'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '~/components/ui/table'
import { Translate } from '~/intl'
import { cn } from '~/utils/cls'
import { appendSearch } from '~/utils/router'

export type ColumnDescriptor<T> = {
	key: keyof T
	header?: ReactNode
	align?: 'left' | 'center' | 'right'
	headerAlign?: 'left' | 'center' | 'right'
	render?: ReactNode | ((item: T) => ReactNode)
	width?: string
	className?: string
	collapse?: boolean
}
export type Columns<T> = readonly ColumnDescriptor<T>[]
export type HasId = { id: string }
export type Props<T extends HasId> = {
	items: T[]
	columns: Columns<T>
	className?: string
	renderExpanded?: (item: T) => ReactNode
	expandLink?: (item: T) => string
	collapseLink?: string
	tableLayout?: 'auto' | 'fixed'
}

export * as CellRenderers from './cell-renderers'
export function DataTable<T extends HasId>({
	columns,
	items,
	renderExpanded,
	expandLink,
	collapseLink,
	className,
	tableLayout,
}: Props<T>): ReactNode {
	const [_, match] = useRoute(`/:selected`)
	const selected = match?.selected
	const search = useSearch()
	const expandable = !!renderExpanded

	return (
		<div className={cn('rounded-md border', className)}>
			<Table style={{ tableLayout }}>
				<TableHeader>
					<TableRow>
						{columns.map(column => (
							<TableHead
								key={column.key?.toString()}
								className={cn(
									'lg:visible lg:w-auto',
									{ 'invisible w-0': column.collapse },
									column.className
								)}
								style={{
									textAlign: column.headerAlign || column.align,
									width: column.width,
								}}
							>
								{column.header}
							</TableHead>
						))}
						{renderExpanded ? <TableHead style={{ width: '40px' }} /> : null}
					</TableRow>
				</TableHeader>
				<TableBody>
					{items.map(item => {
						const isExpanded = selected === item.id
						return (
							<Fragment key={item.id}>
								<TableRow
									className={cn('transition-opacity', {
										'border-b-transparent opacity-50': isExpanded,
									})}
								>
									{columns.map(column => {
										const value = column.render
											? typeof column.render === 'function'
												? column.render(item)
												: column.render
											: item[column.key]?.toString()
										return (
											<TableCell
												key={column.key?.toString()}
												style={{ textAlign: column.align }}
												className={cn(
													'lg:visible lg:w-auto',
													{ 'invisible w-0': column.collapse },
													column.className
												)}
											>
												{value}
											</TableCell>
										)
									})}
									{renderExpanded ? (
										<TableCell className="text-right">
											<Link
												href={
													isExpanded
														? appendSearch(collapseLink || '/', search)
														: appendSearch(
																expandLink?.(item) || `/${item.id}`,
																search
															)
												}
												className="inline-block"
											>
												<ChevronDownIcon
													width={24}
													height={24}
													className={cn('transition-transform', {
														'rotate-180': isExpanded,
													})}
												/>
											</Link>
										</TableCell>
									) : null}
								</TableRow>
								{isExpanded && (
									<TableRow>
										<TableCell colSpan={columns.length + 1} className="pt-0">
											{renderExpanded?.(item)}
										</TableCell>
									</TableRow>
								)}
							</Fragment>
						)
					})}
					{items.length === 0 ? (
						<TableRow>
							<TableCell
								colSpan={columns.length + (expandable ? 1 : 0)}
								className="h-24 text-center"
							>
								<Translate>empty.desc</Translate>
							</TableCell>
						</TableRow>
					) : null}
				</TableBody>
			</Table>
		</div>
	)
}
