import { useEffect, useMemo } from 'react'
import { useLocation, useSearch } from 'wouter'

export function useSearchParams(): URLSearchParams
export function useSearchParams(key: string): string | null
export function useSearchParams(key?: string): string | URLSearchParams | null {
	const search = useSearch()
	const params = useMemo(() => new URLSearchParams(search), [search])
	if (key) {
		return params.get(key)
	} else {
		return params
	}
}

export interface SearchHelper {
	get(key: string): string | null
	set(key: string, value: string, replace?: boolean): void
	set(
		values: Record<string, string | null | undefined>,
		replace?: boolean
	): void
	values: URLSearchParams
}
export const useSearchHelper = (): SearchHelper => {
	const [location, navigate] = useLocation()
	const search = useSearch()

	return useMemo<SearchHelper>(() => {
		const params = new URLSearchParams(search)
		const helper: SearchHelper = {
			get: key => params.get(key),
			set: (
				key: string | Record<string, string | null | undefined>,
				value: string | boolean | undefined,
				replace?: boolean
			) => {
				if (typeof key === 'object') {
					for (const [k, v] of Object.entries(key)) {
						if (v == null) {
							params.delete(k)
						} else {
							params.set(k, v)
						}
					}
					replace = !!value
				} else if (value) {
					params.set(key, value.toString())
				} else {
					params.delete(key)
				}
				const search = params.toString()
				navigate(
					search ? '?' + search : location,
					replace ? { replace: true } : undefined
				)
			},
			values: params,
		}

		return helper
	}, [search, location, navigate])
}

export const appendSearch = (href: string, search?: string): string => {
	if (!search) return href
	return `${href}?${search}`
}

export const useScrollToTop = (): void => {
	const [location] = useLocation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location])
}
