import { useQuery } from '@apollo/client'
import { FC } from 'react'
import { Route, useRoute } from 'wouter'
import { graphql } from '~/__gen__/gql'
import { MasterDetails } from '~/components/master-details'
import { QueueDetails } from './details'
import { QueuesList } from './queues-list'

export * from './models'

const DashboardData = graphql(/* GraphQL */ `
	query QueuesData {
		queues {
			id
			displayName
			paused
			size
			failed
			processed
			running
			histogram(days: 7) {
				date
				completed
				failed
			}
		}
	}
`)
export const Queues: FC = () => {
	const { data, loading, error } = useQuery(DashboardData)

	const [hasDetailsHidden] = useRoute('/')

	return (
		<MasterDetails expanded={!hasDetailsHidden}>
			<QueuesList
				error={error}
				loading={loading}
				items={data?.queues}
				narrow={!hasDetailsHidden}
			/>
			<Route path="/:id" nest>
				{params => <QueueDetails id={params.id} />}
			</Route>
		</MasterDetails>
	)
}
