import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	InMemoryCache,
	concat,
	createHttpLink,
	fromPromise,
} from '@apollo/client'
import { FC, PropsWithChildren } from 'react'
import { globalAuth } from './auth/firebase'
import config from './config'

const httpLink = createHttpLink({
	uri: config.graphUrl,
})
const authMiddleware = new ApolloLink((operation, forward) => {
	return fromPromise(globalAuth.getToken())
		.filter(Boolean)
		.flatMap(token => {
			operation.setContext({
				headers: {
					authorization: token ? `Bearer ${token}` : '',
				},
			})
			return forward(operation)
		})
})

const dateArrayPolicy = {
	merge(_current: unknown, value: string[]) {
		return value?.map(parseDate) || []
	},
}
const dateTypePolicy = {
	merge(_current: unknown, value: unknown) {
		return parseDate(value)
	},
}
const byteArrayPolicy = {
	merge(_current: unknown, value: unknown) {
		return (value as { data: number[] })?.data || null
	},
}

const apollo = new ApolloClient({
	connectToDevTools: config.isDev,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
	},
	link: concat(authMiddleware, httpLink),
	cache: new InMemoryCache({
		addTypename: true,
		typePolicies: {
			QueueItem: {
				fields: {
					created: dateTypePolicy,
					updated: dateTypePolicy,
					started: dateTypePolicy,
					runAfter: dateTypePolicy,
					payload: byteArrayPolicy,
					result: byteArrayPolicy,
				},
			},
			QueueHistoryItem: {
				fields: {
					created: dateTypePolicy,
					updated: dateTypePolicy,
					scheduled: dateTypePolicy,
					payload: byteArrayPolicy,
					result: byteArrayPolicy,
				},
			},
			Queue: {
				fields: {
					created: dateTypePolicy,
					updated: dateTypePolicy,
				},
			},
			Schedule: {
				fields: {
					created: dateTypePolicy,
					updated: dateTypePolicy,
					lastRun: dateTypePolicy,
					nextRun: dateTypePolicy,
					payload: byteArrayPolicy,
				},
			},
			Stats: {
				fields: {
					lastFailedAt: dateTypePolicy,
					nextSchedule: dateTypePolicy,
				},
			},
			ScheduleSimulation: {
				fields: {
					runs: dateArrayPolicy,
				},
			},
			HistogramItem: {
				fields: {
					date: dateTypePolicy,
				},
			},
		},
	}),
})
export const GraphProvider: FC<PropsWithChildren> = ({ children }) => {
	return <ApolloProvider client={apollo}>{children}</ApolloProvider>
}

function parseDate(date: unknown): Date | null {
	if (typeof date === 'string') return new Date(date)
	if (typeof date === 'number') return new Date(date)
	if (date instanceof Date) return date
	return null
}
