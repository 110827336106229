import { Crisp } from 'crisp-sdk-web'
import { FC } from 'react'
import { Translate } from '~/intl'

export const Footer: FC = () => (
	<footer className="mx-auto mt-auto px-4 py-6 text-center text-xs text-gray-500">
		<div className="flex flex-wrap justify-center gap-4">
			<a
				href="https://lambdaqueue.com/terms"
				target="_blank"
				className="hover:underline"
			>
				<Translate>footer.terms</Translate>
			</a>
			<a
				href="https://lambdaqueue.com/privacy"
				target="_blank"
				className="hover:underline"
			>
				<Translate>footer.privacy</Translate>
			</a>
			<a
				href="https://status.lambdaqueue.com"
				target="_blank"
				className="hover:underline"
			>
				<Translate>footer.status</Translate>
			</a>
			<a
				href="https://docs.lambdaqueue.com"
				rel="help"
				target="_blank"
				className="hover:underline"
			>
				<Translate>help</Translate>
			</a>
			<button
				className="unset-all cursor-pointer hover:underline"
				onClick={() =>
					Crisp.chat.isChatOpened() ? Crisp.chat.close() : Crisp.chat.open()
				}
			>
				<Translate>footer.contact</Translate>
			</button>
		</div>
		<div className="mt-4">
			<Translate>footer.copyright</Translate>
		</div>
	</footer>
)
