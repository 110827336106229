export const checkOk = (response: Response): Response => {
	if (!response.ok) {
		throw new Error(response.statusText)
	}
	return response
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseJson = <T = any>(response: Response): Promise<T> => {
	return response.json()
}
