import { FC } from 'react'
import { cn } from '~/utils/cls'

type Props = {
	expanded: boolean
	children: [React.ReactNode, React.ReactNode] | [React.ReactNode]
}
export const MasterDetails: FC<Props> = ({ expanded, children }) => {
	return (
		<div className="flex gap-5">
			<div
				className={cn('min-width-0 flex-1 md:basis-4/12 lg:basis-3/12', {
					'hidden md:block': expanded,
				})}
			>
				{children[0]}
			</div>
			{expanded ? (
				<div className="mt-2 min-w-0 flex-1 md:basis-8/12 lg:basis-9/12">
					{children[1]}
				</div>
			) : null}
		</div>
	)
}
