import { addMonths, format, startOfMonth } from 'date-fns'
import { FC } from 'react'
import { ScheduleSimulation } from '~/__gen__/gql/graphql'
import { Calendar } from '~/components/ui/calendar'
import { useWeekInfo } from '~/intl'

export const Simulation: FC<ScheduleSimulation> = ({ runs, granularity }) => {
	switch (granularity) {
		case 'seconds':
			return <SimulationScroll runs={runs} primary=":ss" secondary="HH:mm" />
		case 'minutes':
		case 'hours':
			return <SimulationScroll runs={runs} primary="HH:mm" secondary="eee" />
		case 'days':
			return <SimulationCalendar runs={runs} />
		case 'months':
			return <SimulationScroll runs={runs} primary="dd" secondary="MMM" />
		case 'years':
			return <SimulationScroll runs={runs} primary="yyyy" secondary="dd MMM" />
		default:
			return <SimulationScroll runs={runs} primary="dd" secondary="MMM" />
	}
}

const SimulationCalendar: FC<{ runs: Date[] }> = ({ runs }) => {
	const thisMonth = startOfMonth(new Date())
	const nextMonth = addMonths(thisMonth, 1)
	const weekInfo = useWeekInfo()

	return (
		<div className="flex flex-wrap justify-center gap-2">
			<Calendar
				className="max-w-[23rem] flex-1"
				disableNavigation
				weekStartsOn={(weekInfo.firstDay % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6}
				fixedWeeks
				showOutsideDays={false}
				selected={runs}
				fromDate={thisMonth}
			/>
			<Calendar
				className="max-w-[23rem] flex-1"
				weekStartsOn={(weekInfo.firstDay % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6}
				disableNavigation
				fixedWeeks
				showOutsideDays={false}
				selected={runs}
				fromDate={nextMonth}
			/>
		</div>
	)
}

const SimulationScroll: FC<{
	runs: Date[]
	primary: string
	secondary: string
}> = ({ runs, primary, secondary }) => (
	<div className="flex min-w-0 flex-1 flex-wrap">
		{runs.map(run => (
			<ScrollItem
				key={run.getTime()}
				primary={format(run, primary)}
				secondary={format(run, secondary)}
			/>
		))}
	</div>
)

const ScrollItem: FC<{
	primary: string | number
	secondary: string | number
}> = ({ primary, secondary }) => (
	<div
		className="w-20 text-nowrap border border-gray-500 px-3 py-2 text-center"
		style={{ marginLeft: '-1px', marginTop: '-1px', borderWidth: '1px' }}
	>
		<div className="text-xs">{secondary}</div>
		<div className="text-xl font-semibold">{primary}</div>
	</div>
)
