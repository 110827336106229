import { FC, ReactNode, useEffect } from 'react'
import { cn } from '~/utils/cls'
import { TableCell, TableRow } from './ui/table'
import { Skeleton } from './ui/skeleton'
import { ErrorPanel } from './error'
import { NotFound } from './not-found'
import { log } from '~/utils/logger'

export const Loading: FC<{ className?: string; size?: number }> = ({
	className,
	size = 32,
}) => (
	<div
		className={cn('loading-indicator', className)}
		style={{
			width: `${size}px`,
			height: `${size}px`,
			backgroundSize: `${size * 0.48}px ${size * 0.48}px`,
		}}
	/>
)

export const WithLoading: FC<{
	loading?: boolean | null
	error?: Error | unknown | null
	notFound?: boolean
	placeholder?: ReactNode | (() => ReactNode)
	children: (() => ReactNode) | ReactNode
	className?: string
}> = ({ loading, children, className, error, placeholder, notFound }) => {
	useEffect(() => {
		if (error) {
			log.error('Error in WithLoading', error)
		}
	}, [error])
	if (loading) {
		if (typeof placeholder === 'function') {
			return placeholder()
		} else if (placeholder) {
			return placeholder
		} else {
			return <Loading className={className} />
		}
	} else if (error) {
		return <ErrorPanel className={className} />
	} else if (notFound) {
		return <NotFound className={className} />
	} else {
		return typeof children === 'function' ? children() : children
	}
}

export const TableLoadingRows: FC<{
	cols: number
	rows: number
	fillCols?: number
	cellClass?: string
}> = ({ cols, rows, fillCols = cols, cellClass }) => (
	<>
		{Array.from({ length: rows }).map((_, i) => (
			<TableRow key={'row-' + i}>
				{Array.from({ length: fillCols }).map((_, j) => (
					<TableCell
						key={'col-' + j}
						colSpan={j === fillCols - 1 ? cols - fillCols + 1 : 1}
					>
						<div className={cn('flex h-9 items-center', cellClass)}>
							<Skeleton
								className="h-3 rounded-full"
								style={{ width: `${Math.random() * 40 + 60}%` }}
							/>
						</div>
					</TableCell>
				))}
			</TableRow>
		))}
	</>
)

export const TextSkeleton: FC<{ className?: string }> = ({ className }) => (
	<div className={cn('align-center inline-flex', className)}>
		<Skeleton className="flex-1" />
		<span className="hidden">Z</span>
	</div>
)
