const isDev = process.env.NODE_ENV === 'development'
export default {
	graphUrl: isDev ? '/graph' : 'https://api.lambdaqueue.com/graph',
	apiUrl: isDev ? '/webapi' : 'https://api.lambdaqueue.com/webapi',
	isDev: isDev,
	crisp: {
		websiteId: '4c249080-2675-4b3d-8662-e07323910372',
	},
	firebase: {
		apiKey: 'AIzaSyCTdjG-lJeIG47QFbiu_2dS0wzj6bWdmO4',
		authDomain: 'lambda-queue.firebaseapp.com',
		projectId: 'lambda-queue',
		storageBucket: 'lambda-queue.appspot.com',
		messagingSenderId: '749451098461',
		appId: '1:749451098461:web:228d230509d971f5ad10b4',
	},
}
