import { FC, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { graphql } from '~/__gen__/gql'
import { ErrorPanel } from '~/components/error'
import { AccessTokens } from './api-tokens'
import { CreatedApiToken, useSecurityApi } from './webapi'
import { SignSecret } from './sign-secret'

const SettingsData = graphql(/* GraphQL */ `
	query SettingsData {
		accessTokens {
			id
			name
			expires
		}
		signSecret
	}
`)
const DeleteToken = graphql(/* GraphQL */ `
	mutation DeleteToken($id: ID!) {
		deleteAccessToken(id: $id)
	}
`)

export const Settings: FC = () => {
	const { data, error, loading, refetch } = useQuery(SettingsData)
	const [deleteTokenMutation] = useMutation(DeleteToken)

	//This has to be REST so we don't leak token anywhere outside of this UI
	const securityApi = useSecurityApi()

	const deleteToken = useCallback(
		(id: string): void => {
			deleteTokenMutation({ variables: { id } }).then(() => refetch())
		},
		[deleteTokenMutation, refetch]
	)
	const createToken = (
		name: string | null | undefined,
		neverExpire: boolean
	): Promise<CreatedApiToken> =>
		securityApi.createToken(name, neverExpire).finally(() => {
			refetch()
		})

	if (error) return <ErrorPanel />

	return (
		<div className="mt-4 flex flex-col gap-4">
			<SignSecret secret={data?.signSecret} loading={loading} />
			<AccessTokens
				tokens={data?.accessTokens ?? []}
				onDelete={deleteToken}
				onCreate={createToken}
				loading={loading}
			/>
		</div>
	)
}
