import { FC, PropsWithChildren } from 'react'
import { SearchInput } from '~/components/search-input'
import { UserHeader } from '~/components/user-header'
import { MainMenu } from './main-menu'
import { Footer } from './footer'

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className="flex flex-1 flex-col md:container">
			<div className="flex w-full flex-grow flex-col overflow-hidden sm:flex-row">
				<main role="main" className="flex-grow overflow-auto p-4">
					<UserHeader />
					<div className="py-6">
						<SearchInput />
					</div>
					<MainMenu className="mb-4" />
					{children}
				</main>
			</div>
			<Footer />
		</div>
	)
}
