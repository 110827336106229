import {
	ChevronLeftIcon,
	ChevronRightIcon,
	DoubleArrowLeftIcon,
	DoubleArrowRightIcon,
} from '@radix-ui/react-icons'

import { FC, useMemo } from 'react'
import { Link, useLocation, useSearch } from 'wouter'
import { PageInfo } from '~/__gen__/gql/graphql'
import { Button } from '~/components/ui/button'
import { Translate } from '~/intl'
import { cn } from '~/utils/cls'

type Props = {
	total: number
	pages: number
	pageNo: number
	nextLink?: string
	prevLink?: string
	firstLink?: string
	lastLink?: string
	className?: string
}
export const GraphPagination: FC<{
	className?: string
	page?: PageInfo
}> = ({ className, page }) => {
	const links = usePaginationLinks(page)
	return (
		<Pagination
			className={className}
			total={page?.total || 0}
			pages={page?.pages || 0}
			pageNo={page?.pageNo || 0}
			nextLink={links.nextLink}
			prevLink={links.prevLink}
			firstLink={links.firstLink}
			lastLink={links.lastLink}
		/>
	)
}
export const Pagination: FC<Props> = ({
	total,
	pages,
	pageNo,
	nextLink,
	prevLink,
	firstLink,
	lastLink,
	className,
}) => {
	return (
		<div className={cn('flex items-center justify-between px-2', className)}>
			<div className="text-muted-foreground flex-1 text-sm">
				<Translate count={total}>table.pagination.total</Translate>
			</div>
			<div className="flex items-center space-x-6 lg:space-x-8">
				<div className="flex items-center justify-center text-sm font-medium">
					<Translate page={pageNo + 1} total={pages}>
						table.pagination.showing
					</Translate>
				</div>
				<div className="flex items-center space-x-2">
					<Link
						href={firstLink || ''}
						aria-disabled={!firstLink}
						className={firstLink ? '' : 'disabled'}
					>
						<Button
							variant="outline"
							className="hidden h-8 w-8 p-0 lg:flex"
							disabled={!firstLink}
						>
							<span className="sr-only">
								<Translate>table.pagination.goto_start</Translate>
							</span>
							<DoubleArrowLeftIcon className="h-4 w-4" />
						</Button>
					</Link>
					<Link
						href={prevLink || ''}
						aria-disabled={!prevLink}
						className={prevLink ? '' : 'disabled'}
					>
						<Button
							variant="outline"
							className="hidden h-8 w-8 p-0 lg:flex"
							disabled={!prevLink}
						>
							<span className="sr-only">
								<Translate>table.pagination.goto_prev</Translate>
							</span>
							<ChevronLeftIcon className="h-4 w-4" />
						</Button>
					</Link>
					<Link
						href={nextLink || ''}
						aria-disabled={!nextLink}
						className={nextLink ? '' : 'disabled'}
					>
						<Button
							variant="outline"
							className="hidden h-8 w-8 p-0 lg:flex"
							disabled={!nextLink}
						>
							<span className="sr-only">
								<Translate>table.pagination.goto_next</Translate>
							</span>
							<ChevronRightIcon className="h-4 w-4" />
						</Button>
					</Link>
					<Link
						href={lastLink || ''}
						aria-disabled={!lastLink}
						className={lastLink ? '' : 'disabled'}
					>
						<Button
							variant="outline"
							className="hidden h-8 w-8 p-0 lg:flex"
							disabled={!lastLink}
						>
							<span className="sr-only">
								<Translate>table.pagination.goto_end</Translate>
							</span>
							<DoubleArrowRightIcon className="h-4 w-4" />
						</Button>
					</Link>
				</div>
			</div>
		</div>
	)
}

type PaginationLinksHook = {
	prevLink?: string
	nextLink?: string
	firstLink?: string
	lastLink?: string
}
export const usePaginationLinks = (
	page: PageInfo | undefined
): PaginationLinksHook => {
	const search = useSearch()
	const [location] = useLocation()
	const prevLink = useMemo(() => {
		if (!page?.prevCursor) return undefined
		const params = new URLSearchParams(search)
		params.set('after', page.prevCursor)
		params.delete('before')
		return '?' + params.toString()
	}, [page?.prevCursor, search])

	const nextLink = useMemo(() => {
		if (!page?.nextCursor) return undefined
		const params = new URLSearchParams(search)
		params.set('before', page.nextCursor)
		params.delete('after')
		return '?' + params.toString()
	}, [page?.nextCursor, search])

	const firstLink = useMemo(() => {
		if (!page?.pages || page?.pages < 2) return undefined
		const params = new URLSearchParams(search)
		params.delete('before')
		params.delete('after')
		return params.size ? '?' + params.toString() : location
	}, [location, page?.pages, search])

	const lastLink = useMemo(() => {
		if (!page?.endCursor || page?.pages < 2) return undefined
		const params = new URLSearchParams(search)
		params.set('before', page?.endCursor)
		params.delete('after')
		return '?' + params.toString()
	}, [search, page?.endCursor, page?.pages])

	return {
		prevLink,
		nextLink,
		firstLink,
		lastLink,
	}
}
