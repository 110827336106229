import { FC, ReactNode, useId } from 'react'
import { Checkbox } from './ui/checkbox'

export const CheckboxWithDescription: FC<{
	checked?: boolean
	onChange: (checked: boolean) => unknown
	label: ReactNode
	description: ReactNode
}> = ({ checked, label, description, onChange }) => {
	const id = useId()
	return (
		<div className="items-top flex space-x-2">
			<Checkbox
				id={id}
				checked={checked}
				onCheckedChange={state => {
					if (typeof state === 'boolean') {
						return onChange(state)
					}
				}}
			/>
			<div className="grid gap-1.5 leading-none">
				<label
					htmlFor={id}
					className="mt-[1px] cursor-pointer text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
				>
					{label}
				</label>
				<p className="text-muted-foreground text-sm">{description}</p>
			</div>
		</div>
	)
}
